import { ChangeDetectorRef, Component, computed, EventEmitter, Input, Output, signal, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import {
  NgxDaterangepickerBootstrapComponent,
  NgxDaterangepickerBootstrapDirective,
} from 'ngx-daterangepicker-bootstrap';

import * as Types from '../../shared/types';
import dayjs, { Dayjs } from 'dayjs';
import * as Constants from '../../shared/constant';
import { SharedDataService } from '../../services/shared-data.service';
import { CommonService } from '../../services/common.service';
import { ButtonComponent } from '../button/button.component';
import { TruncatePipe } from '../../pipes/truncate.pipe';
import { OutsideClickDirective } from '../../directives/outside-click.directive';
import { DateRangePickerService } from './date-range-picker.service';
import { Subscription } from 'rxjs';

export interface DateObject extends Object {
  startDate: dayjs.Dayjs;
  endDate: dayjs.Dayjs;
  label: string;
}

@Component({
  selector: 'date-range-picker',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    MatInputModule,
    NgxDaterangepickerBootstrapDirective,
    NgxDaterangepickerBootstrapComponent,
    ButtonComponent,
    TruncatePipe,
    OutsideClickDirective,
  ],
  templateUrl: './date-range-picker.component.html',
  styleUrl: './date-range-picker.component.scss',
})
export class DaterangePickerComponent {
  constructor(
    private cdr: ChangeDetectorRef,
    public sharedDataService: SharedDataService,
    public commonService: CommonService,
    private dateRangePickerService: DateRangePickerService
  ) {}

  @ViewChild(NgxDaterangepickerBootstrapDirective, { static: false }) pickerDirective:
    | NgxDaterangepickerBootstrapDirective
    | undefined;
  @Input() customFormat: string = 'YYYY-MM-DD';
  @Output() onDateSelect = new EventEmitter<string | string[]>();

  private toggleValuesSubscription!: Subscription;
  id = Math.random();
  isInitialized = false;
  divWidthTop: string = '70px'; // Default width
  divWidthBottom: string = '65px'; // Default width
  // dropdownOpened: boolean = false;
  protected _dropdownOpened = signal<boolean>(false);
  dropsDown = 'down';
  dropsUp = 'up';
  opensRight = 'right';
  opensCenter = 'center';
  opensLeft = 'left';
  selectedRangeCalendar = computed<Types.SelectedRangeCalendar>(() =>
    this.dateRangePickerService.selectedRangeCalendar()
  );
  maxDate?: Dayjs;
  minDate?: Dayjs;
  invalidDates: Dayjs[] = [];
  startAPIDate: string | undefined;
  endAPIDate: string | undefined;
  selectedDate: string | undefined;
  referenceDate = Constants.ReferenceDate;
  ranges = Constants.Ranges;
  localeTime = Constants.LocaleTime;
  locale = Constants.Locale;
  tooltips = [
    { date: dayjs(), text: '' },
    { date: dayjs().add(2, 'days'), text: '' },
  ];

  ngOnInit() {
    this.toggleValuesSubscription = this.sharedDataService.getToggleValues().subscribe((values) => {
      if (!values.date) {
        this._dropdownOpened.set(values.date);
        this.pickerDirective?.hide();
      }
    });

    this.setWidth(this.selectedRangeCalendar() as DateObject);
    if (!this.dateRangePickerService.dateInitialized()) {
      let updatedRangeObject = {
        startDate: this.ranges['Last 7 days'][0],
        endDate: this.ranges['Last 7 days'][1],
        label: '',
      };

      this.sharedDataService.setDateRangeObject({ ...updatedRangeObject });

      this.sharedDataService.setDateRange({
        startAPIDate: updatedRangeObject.startDate.format('YYYY-MM-DD'),
        endAPIDate: updatedRangeObject.endDate.format('YYYY-MM-DD'),
        dateSelected: true,
      });
    }

    this.isInitialized = true;
    this.dateRangePickerService.setDateInitialized(true);
  }

  ngOnDestroy() {
    if (this.toggleValuesSubscription) {
      this.toggleValuesSubscription.unsubscribe();
    }
    this.cdr.detectChanges();
  }

  isInvalidDate = (m: Dayjs) => {
    return this.invalidDates.some((d) => d.isSame(m, 'day'));
  };

  isCustomDates = (date: Dayjs) => {
    return date.month() === 0 || date.month() === 6 ? 'mycustomdate' : false;
  };

  isTooltipDate = (m: Dayjs) => {
    const tooltip = this.tooltips.find((tt) => tt.date.isSame(m, 'day'));
    return tooltip ? tooltip.text : false;
  };

  toTitleCase = (str: string) => {
    return str.replace(/\w\S*/g, (text) => text.charAt(0).toUpperCase() + text.substring(1).toLowerCase());
  };

  setWidth(selectedRangeCalendar: { startDate: dayjs.Dayjs; endDate: dayjs.Dayjs; label?: string }) {
    if (selectedRangeCalendar.label) {
      if (selectedRangeCalendar.label.length < 12) {
        this.divWidthTop = '120px';
        this.divWidthBottom = '114px';
      } else if (selectedRangeCalendar.label.length < 15) {
        this.divWidthTop = '135px';
        this.divWidthBottom = '129px';
      } else {
        this.divWidthTop = '223px';
        this.divWidthBottom = '217px';
      }
    }
  }

  datesUpdatedRange($event: object) {
    let updatedRangeObject = $event as DateObject;

    let selectedRangeCalendarTemp = { ...this.selectedRangeCalendar() };
    if (updatedRangeObject?.startDate) {
      this.dateRangePickerService.setSelectedRangeCalendar(updatedRangeObject);
      this.setWidth(updatedRangeObject as DateObject);
    }

    // this._dropdownOpened.set(false);
    if (updatedRangeObject?.label) {
      selectedRangeCalendarTemp.label = this.toTitleCase(updatedRangeObject.label);

      if (selectedRangeCalendarTemp.label === 'Custom Range') {
        selectedRangeCalendarTemp.label = `${updatedRangeObject.startDate.format('DD MMM YYYY')} - ${updatedRangeObject.endDate.format('DD MMM YYYY')}`;
      }

      this.sharedDataService.setDateRangeObject({ ...updatedRangeObject });

      this.startAPIDate = updatedRangeObject.startDate.format('YYYY-MM-DD');
      this.endAPIDate = updatedRangeObject.endDate.format('YYYY-MM-DD');

      if (updatedRangeObject?.startDate) {
        this.sharedDataService.setDateRange({
          startAPIDate: this.startAPIDate,
          endAPIDate: this.endAPIDate,
          dateSelected: true,
        });
      }

      this.commonService.historyPageNumber = 1;
      // this.commonService.setLoaderFlag(true);
    }
  }

  makeLoaderTrue(event: MouseEvent) {
    this.commonService.setLoaderFlag(true);
    this.cdr.detectChanges();
  }

  toggleDropdown = () => {
    if (this.pickerDirective) {
      this.pickerDirective.toggle();
      // this.sharedDataService.toggleDate(!this._dropdownOpened());
    }
  };

  handleOutSideClick = () => {
    this._dropdownOpened.set(false);
    // this.pickerDirective?.hide();
    this.cdr.detectChanges();
  };

  getDateLabel = () => {
    return this.selectedRangeCalendar().label ?? '';
  };
}
