import { CommonModule } from '@angular/common';
import { Component, OnInit, ViewEncapsulation, Input, ViewChild } from '@angular/core';
import { MatTabsModule } from '@angular/material/tabs';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { HighchartsChartModule } from 'highcharts-angular';

import { ChatBoxComponent } from './chat-box/chat-box.component';
import { MyReportComponent } from './my-report/my-report.component';
import { DataExplorerComponent } from './data-explorer/data-explorer.component';
import { CommonService } from '../../services/common.service';
import { RightSidebarComponent } from './right-sidebar/right-sidebar.component';
import { LabelService } from '../../services/label.service';
import { Dashboard, LabelTypes } from '../../shared/label.types';
import { ConversationOverviewComponent } from '../conversation-overview/conversation-overview.component';
import * as Types from '../../shared/types';
import { TabularViewComponent } from './tabular-view/tabular-view.component';
import { DaterangePickerComponent } from '../date-range-picker/date-range-picker.component';
import { ButtonComponent } from '../button/button.component';
import { HideFieldsComponent } from '../hide-fields/hide-fields.component';
import { CustomSelectComponent } from '../custom-select/custom-select.component';
import { FilterSectionComponent } from '../filter-section/filter-section.component';
import { SharedDataService } from '../../services/shared-data.service';
import { transformColumnPreferences, transformTestToFilters } from '../../shared/appUtil';
import { FilterService } from '../../services/filter.service';
import { MoreDropDownComponent } from './more-drop-down/more-drop-down.component';

@Component({
  selector: 'app-dashboard',
  standalone: true,
  imports: [
    CommonModule,
    MatTabsModule,
    MatMenuModule,
    MatIconModule,
    HighchartsChartModule,
    ChatBoxComponent,
    MyReportComponent,
    DataExplorerComponent,
    RightSidebarComponent,
    ConversationOverviewComponent,
    TabularViewComponent,
    DaterangePickerComponent,
    ButtonComponent,
    HideFieldsComponent,
    FilterSectionComponent,
    CustomSelectComponent,
    HideFieldsComponent,
    MoreDropDownComponent,
  ],
  providers: [CommonService],
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DashboardComponent implements OnInit {
  @ViewChild(TabularViewComponent) tableComponent!: TabularViewComponent;
  isChatBoxVisible: boolean = false;
  dashboard: Dashboard = {} as Dashboard;
  activeTab: string = 'visualization'; // Default active tab
  tableViewFlag: boolean = false;
  columnLoading: boolean = false;
  tableViewBody: Types.TableViewBody = {
    filters: [],
  } as Types.TableViewBody;
  content: Array<{ label: string; value: string }> = [
    { label: 'option1', value: 'option1' },
    { label: 'option2', value: 'option2' },
    { label: 'option3', value: 'option3' },
  ];
  selectedValue = { value: 'contains', label: 'contains' };
  buttonTitle: string = 'Hide Fields';
  // userColumnKey = 'user_column_preference';
  // columnPreferenceDeletedFlag = 'column_preference_deleted_flag';

  constructor(
    public commonService: CommonService,
    public labelService: LabelService,
    private sharedDataService: SharedDataService,
    private filterService: FilterService
  ) { }

  ngOnInit() {
    this.labelService.getLabelData().subscribe((data: LabelTypes) => {
      this.dashboard = data?.dashboard ?? {};
    });

    this.filterService.getRefreshDataStatus().subscribe((status) => {
      if (status) {
        this.tableViewBody.filters = this.populateFilteredData();
        this.fetchOverview();
        //this.fetchFilters();
      }
    });

    this.sharedDataService.getDateRange().subscribe((date) => {
      this.tableViewBody.start_date = date.startAPIDate;
      this.tableViewBody.end_date = date.endAPIDate;

      if (date?.startAPIDate) {
        this.fetchOverview();
      }
    });

    this.fetchColumnNames();
    this.fetchFilters();
  }

  //download table data csv file
  onDownload() {
    if (this.tableViewFlag) {
      this.tableComponent.exportPlot();
    }
  }

  viewSavedFilters() { }

  hideInsightsOverview() { }

  fetchColumnNames = async () => {
    this.columnLoading = true;
    this.commonService.fetchColumnNames({}).subscribe({
      next: (names: Array<Types.ColumnPreferenceType>) => {
        this.sharedDataService.setColumnNames(transformColumnPreferences(names));
        this.columnLoading = false;
      },
      error: () => {
        this.columnLoading = false;
      },
    });
  };

  fetchOverview = () => {
    this.commonService.fetchOverview(this.tableViewBody).subscribe({
      next: (data: Types.Overview) => {
        this.sharedDataService.setConversationOverview(data);
      },
      error: (error) => {
        console.error('API Error:', error);
      },
    });
  };

  populateFilteredData(): Types.FilterRequest[] {
    const filterDataArray = this.filterService.getFilterConditionDataValue();
    const filteredData: Types.FilterRequest[] = [...transformTestToFilters(filterDataArray)];
    return filteredData;
  }

  fetchFilters = () => {
    this.commonService.fetchDataFilters({ filters: this.populateFilteredData() }).subscribe({
      next: (data: Types.DataFilters) => {
        this.filterService.setAllFilters(data);

        // Call setSelectedDomain after successfully setting all filters
        const filteredData = this.populateFilteredData();
        this.setSelectedDomain(filteredData);
      },
      error: (error) => {
        console.error('Error fetching all filters', error);
      },
    });
  };

  setSelectedDomain(filteredData: any[]) {
    const domainFilter = filteredData.find(item => item.columnName === 'Domain');

    if (domainFilter) {
      // If 'Domain' column is found, set selectedDomain to its value
      this.commonService.selectedDomain = domainFilter.value;
    } else {
      // If 'Domain' column is not found, set selectedDomain to an empty string
      this.commonService.selectedDomain = '';
    }
  }

  handleItemSelect = (value: { label: string; value: string }) => {
    this.selectedValue = value;
  };

  onValueChange(newValue: any) {
    this.isChatBoxVisible = newValue;
  }

  showTab(tab: string): void {
    this.activeTab = tab;
  }

  showChartView(): void {
    if (this.tableViewFlag) {
      this.tableViewFlag = false;
    }
  }

  showDataView(): void {
    if (!this.tableViewFlag) {
      this.tableViewFlag = true;
    }
  }

  toggleDropdown() { }
}
