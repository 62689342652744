import dayjs, { Dayjs } from 'dayjs';
import { FilterCondition } from './types';

export const ResolutionBarChartOptions = {
  chart: {
    type: 'bar',
  },
  title: {
    text: '',
  },
  credits: {
    enabled: false,
  },
  xAxis: {
    categories: ['Resolution'],
    labels: {
      style: {
        fontSize: '12px',
      },
    },
  },
  yAxis: {
    title: {
      text: 'Frequency',
    },
  },
  series: [],
};

export const PercentageAreaPrimaryTopicChartOptions: Highcharts.Options = {
  chart: {
    type: 'area',
  },
  title: {
    text: '',
  },
  credits: {
    enabled: false,
  },
  xAxis: {
    categories: [],
    gridLineWidth: 0,
  },
  yAxis: {
    title: {
      text: 'Percentage',
    },
    tickPositions: [0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1],
    gridLineWidth: 1,
  },
  plotOptions: {
    area: {
      stacking: 'percent',
      lineColor: 'none',
      lineWidth: 1,
      marker: {
        enabled: true,
      },
    },
  },
  legend: {
    layout: 'vertical',
    align: 'right',
    verticalAlign: 'top',
    borderWidth: 0,
    itemWidth: 100,
  },
  series: [],
};

export const PercentageAreaCustomerIntentChartOptions: Highcharts.Options = {
  chart: {
    type: 'area',
  },
  title: {
    text: '',
  },
  credits: {
    enabled: false,
  },
  xAxis: {
    categories: [],
    gridLineWidth: 0,
  },
  yAxis: {
    title: {
      text: 'Percentage',
    },
    tickPositions: [0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1],
    gridLineWidth: 1,
  },
  plotOptions: {
    area: {
      stacking: 'percent',
      lineColor: 'none',
      lineWidth: 1,
      marker: {
        enabled: true,
      },
    },
  },
  legend: {
    layout: 'vertical',
    align: 'right',
    verticalAlign: 'top',
    borderWidth: 0,
    itemWidth: 100,
  },
  series: [],
};

export const SankeyDiagram1Options = {
  chart: {
    type: 'sankey',
  },
  credits: {
    enabled: false,
  },
  title: {
    text: '',
  },
  series: [],
};

export const SankeyDiagram2Options: Highcharts.Options = {
  chart: {
    type: 'sankey',
  },
  credits: {
    enabled: false,
  },
  title: {
    text: '',
  },
  plotOptions: {
    sankey: {
      colors: ['#f7a35c', '#7cb5ec', '#90ed7d', '#434348', '#8085e9', '#f15c80'],
    },
  },
  legend: {
    layout: 'vertical',
    align: 'left',
    verticalAlign: 'middle',
  },
  series: [],
};

export const PrimaryTopicBarChartOptions = {
  chart: {
    type: 'bar',
  },
  title: {
    text: '',
  },
  credits: {
    enabled: false,
  },
  xAxis: {
    categories: ['Primary Topic'],
    labels: {
      style: {
        fontSize: '12px',
      },
    },
  },
  yAxis: {
    title: {
      text: 'Frequency',
    },
  },
  series: [],
};

export const Nodes = [
  {
    id: 'Customer Sentiment At Start: Negative',
    name: ' Negative ',
    color: '#d4d4d4',
  },
  {
    id: 'Customer Sentiment At End: Negative',
    name: ' Negative ',
    color: '#d4d4d4',
  },
  {
    id: 'Customer Sentiment At Start: Positive',
    name: ' Positive ',
    color: '#d4d4d4',
  },
  {
    id: 'Customer Sentiment At End: Positive',
    name: ' Positive ',
    color: '#d4d4d4',
  },
  {
    id: 'Customer Sentiment At Start: Neutral',
    name: ' Neutral ',
    color: '#d4d4d4',
  },
  {
    id: 'Customer Sentiment At End: Neutral',
    name: ' Neutral ',
    color: '#d4d4d4',
  },
];

export const LocaleTime = {
  firstDay: 1,
  startDate: dayjs().startOf('day'),
  endDate: dayjs().endOf('day'),
  format: 'YYYY-MM-DD',
  separator: ' to ',
  daysOfWeek: ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'],
  monthNames: [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ],
  applyLabel: 'Apply',
  cancelLabel: 'Cancel',
  fromLabel: 'From',
  toLabel: 'To',
};

export const Locale = {
  firstDay: 1,
  startDate: dayjs().startOf('day'),
  endDate: dayjs().endOf('day'),
  format: 'YYYY-MM-DD',
  separator: ' to ',
  daysOfWeek: ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'],
  monthNames: [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ],
  applyLabel: 'Apply',
  cancelLabel: 'Cancel',
  fromLabel: 'From',
  toLabel: 'To',
};

export const DropdownSettings = {
  singleSelection: false,
  idField: 'filterId',
  textField: 'filterText',
  selectAllText: 'Select All',
  unSelectAllText: 'Unselect All',
  itemsShowLimit: 3,
  allowSearchFilter: true,
};

export const Durations = [
  { d_id: 4, name: 'Last 7 Days' },
  { d_id: 0, name: 'This Month' },
  { d_id: 1, name: 'Last Month' },
  { d_id: 2, name: 'Last Three Months' },
  { d_id: 3, name: 'Last Six Months' },
  { d_id: 5, name: 'Custom' },
];

export const DurationDropdownSettings = {
  singleSelection: true,
  idField: 'd_id',
  textField: 'name',
  itemsShowLimit: 1,
  allowSearchFilter: false,
  showSelectedItemsAtTop: false,
  defaultOpen: false,
  maxHeight: 130,
};

export const OperatorContent = [
  { label: 'And', value: 'And' },
  { label: 'Or', value: 'Or' },
];

export const FILTER_OPERATIONS = {
  string: [
    { label: 'contains', value: 'contains' },
    { label: 'does not contain', value: 'does_not_contain' },
    { label: 'is', value: 'is' },
    { label: 'is not', value: 'is_not' },
    { label: 'is empty', value: 'is_empty' },
    { label: 'is not empty', value: 'is_not_empty' },
  ],
  date: [
    { label: 'on', value: 'on' },
    { label: 'before', value: 'before' },
    { label: 'after', value: 'after' },
    { label: 'on or before', value: 'on_or_before' },
    { label: 'on or after', value: 'on_or_after' },
    { label: 'date range', value: 'date_range' },
    { label: 'is empty', value: 'is_empty' },
    { label: 'is not empty', value: 'is_not_empty' },
  ],
  integer: [
    { label: '=', value: '=' },
    { label: '≠', value: '!=' },
    { label: '>', value: '>' },
    { label: '<', value: '<' },
    { label: '>=', value: '>=' },
    { label: '<=', value: '<=' },
    { label: 'is empty', value: 'is_empty' },
    { label: 'is not empty', value: 'is_not_empty' },
  ],
  cluster: [
    { label: 'contains', value: 'contains' },
    { label: 'is', value: 'is' },
    { label: 'is empty', value: 'is_empty' },
    { label: 'is not empty', value: 'is_not_empty' },
  ], // Operations for cluster fields
  array: [
    { label: 'has any of', value: 'has_any_of' },
    { label: 'has all of', value: 'has_all_of' },
    { label: 'is exactly', value: 'is_exactly' },
    { label: 'has none of', value: 'has_none_of' },
    { label: 'is empty', value: 'is_empty' },
    { label: 'is not empty', value: 'is_not_empty' },
  ],
  singleArray: [
    { label: 'is', value: 'is' },
    { label: 'is not', value: 'is_not' },
    { label: 'contains', value: 'contains' },
    { label: 'does not contain', value: 'does_not_contain' },
    { label: 'is empty', value: 'is_empty' },
    { label: 'is not empty', value: 'is_not_empty' },
  ],
};

export const Contains = 'contains';
export const DoesNotContain = 'does_not_contain';

export const DisableContent = {
  isEmpty: { label: 'is empty', value: 'is_empty' },
  isNotEmpty: { label: 'is not empty', value: 'is_not_empty' },
};

export const MonthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const InitialToggleValues = {
  date: false,
  filter: false,
  group: false,
  hideFields: false,
  moreOptions: false,
  sort: false,
  search: false,
  timeZone: false,
};

export const SaveFilterValues = [
  { label: 'option A', value: 'option A' },
  { label: 'option B', value: 'option B' },
];

export const InitialFilterData: FilterCondition = {
  index: 1,
  operator: null,
  selectedColumn: undefined,
  selectedOperation: undefined,
  value: '',
  isCluster: false,
  clusterData: [],
};

export const TruncateLimitCustomSelect = 20;

export const DateFormatColumns = [
  'ContactTimestamp',
  'Date',
  'Agent_ConnectedToAgentTimestamp',
  'Agent_AfterContactWorkEndTimestamp',
  'Agent_AfterContactWorkStartTimestamp',
  'Attributes_callbackDeQueuedTime',
  'Attributes_callbackEnQueuedTime',
  'ConnectedToSystemTimestamp',
  'DisconnectTimestamp',
  'InitiationTimestamp',
  'LastUpdateTimestamp',
  'Queue_DequeueTimestamp',
  'Queue_EnqueueTimestamp',
  'ScheduledTimestamp',
  'TransferCompletedTimestamp',
];

export const SingleArrayValues = ['CustomerSentimentAtStart', 'CustomerSentimentAtEnd', 'Intent', 'PrimaryTopic'];

export const FilterKeyMap: Record<string, string> = {
  CustomerSentimentAtStart: 'customersentimentatstart',
  CustomerSentimentAtEnd: 'customersentimentatend',
  Intent: 'intent',
  PrimaryTopic: 'primarytopic',
};

export const ReferenceDate = dayjs().subtract(1, 'day');
export const Ranges = {
  'Last 7 days': [ReferenceDate.subtract(6, 'days').startOf('day'), ReferenceDate.endOf('day')],
  'This month': [ReferenceDate.startOf('month'), ReferenceDate.endOf('month')],
  'Last 3 months': [
    ReferenceDate.subtract(3, 'months').startOf('month'),
    ReferenceDate.subtract(1, 'month').endOf('month'),
  ],
  'Last 6 months': [
    ReferenceDate.subtract(6, 'months').startOf('month'),
    ReferenceDate.subtract(1, 'month').endOf('month'),
  ],
} as any;

export const InitialDate = {
  startDate: Ranges['Last 7 days'][0],
  endDate: Ranges['Last 7 days'][1],
  label: 'Last 7 Days',
};

export const PopupConfirmationData = {
  deleteConfirmation: {
    title: 'Confirm Action',
    message: 'Are you sure you want to delete the filter?',
    iconUrl: 'assets/icons/warning_amber.svg',
    confirmBtnText: 'Delete',
  },
};

export const ManageColumnCount = 20;

export const MultiSelectorColumns = ['Topics', 'PrimaryTopic', 'Intent'];

export enum ClusterColumns {
  RootCause = 'RootCause',
  CustomerPainPoint = 'CustomerPainPoint',
  Outcome = 'Outcome',
  Trigger = 'Trigger',
}

export enum ClusterNames {
  RootCause = 'RootCauseClustered',
  CustomerPainPoint = 'CustomerPainPointClustered',
  Outcome = 'OutcomeClustered',
  Trigger = 'TriggerClustered',
}

export const TIMEZONE_LIST = [
  { label: 'UTC (Universal)', value: 'UTC' },
  { label: 'EST (America/Toronto)', value: 'EST' },
  { label: 'CST (America/Chicago)', value: 'CST' },
  { label: 'MST (America/Denver)', value: 'MST' },
  { label: 'PST (America/Los Angeles)', value: 'PST' },
  { label: 'IST (Asia/Kolkata)', value: 'IST' },
  { label: 'BST (Europe/London)', value: 'BST' },
  { label: 'CET (Europe/Paris)', value: 'CET' },
  { label: 'EET (Europe/Bucharest)', value: 'EET' },
  { label: 'JST (Asia/Tokyo)', value: 'JST' },
  { label: 'AEST (Australia/Sydney)', value: 'AEST' },
];



// #frontend timezone abbreviations to valid pytz timezones
const TIMEZONE_MAPPING = {
    "UTC": "UTC",
    "EST": "America/Toronto",
    "CST": "America/Chicago",
    "MST": "America/Denver",
    "PST": "America/Los_Angeles",
    "IST": "Asia/Kolkata",
    "BST": "Europe/London",
    "CET": "Europe/Paris",
    "EET": "Europe/Bucharest",
    "JST": "Asia/Tokyo",
    "AEST": "Australia/Sydney",
}