import { Component, Input, signal } from '@angular/core';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { ChangeDetectorRef } from '@angular/core';
import { CustomSelectComponent } from '../../custom-select/custom-select.component';
import { ButtonComponent } from '../../button/button.component';
import * as Types from '../../../shared/types';
import { FilterService } from '../../../services/filter.service';
import { FILTER_OPERATIONS, OperatorContent, DisableContent, FilterKeyMap } from '../../../shared/constant';
import { CustomInputComponent } from '../../custom-input/custom-input.component';
import { transformTableHeaders } from '../../../shared/appUtil';
import { LabelService } from '../../../services/label.service';
import { Filters, LabelTypes } from '../../../shared/label.types';
import { TopicsFieldComponent } from '../../topics-field/topics-field.component';
import { SharedDataService } from '../../../services/shared-data.service';
import { PrimaryTopicComponent } from '../primary-topic/primary-topic.component';
import { IntentComponent } from '../intent/intent.component';

@Component({
  selector: 'filter-item',
  standalone: true,
  imports: [
    CustomSelectComponent,
    DragDropModule,
    ButtonComponent,
    CommonModule,
    CustomInputComponent,
    TopicsFieldComponent,
    PrimaryTopicComponent,
    IntentComponent
  ],
  templateUrl: './filter-item.component.html',
  styleUrl: './filter-item.component.scss',
})
export class FilterItemComponent {
  constructor(
    private filterService: FilterService,
    public labelService: LabelService,
    private cdr: ChangeDetectorRef,
    private sharedDataService: SharedDataService
  ) { }

  @Input() filterItem: Types.FilterCondition = {} as Types.FilterCondition;
  @Input() filterItems: Types.FilterCondition[] = []; // Array of filter items for drag and drop
  @Input() fileName: string | null = null;

  _arrayData = signal<Array<Types.DropdownObjectType>>([]);
  _selectedArrayValue = signal<Types.DropdownObjectType>({ label: '', value: '' });

  operationContent: Array<Types.DropdownObjectType> = FILTER_OPERATIONS.string;
  columnNames: Array<Types.DropdownObjectType> = [];
  operatorContent: Array<Types.DropdownObjectType> = OperatorContent;
  _filterItemLocal: Types.FilterItemLocal = {} as Types.FilterItemLocal;
  filterData: Array<Types.FilterCondition> = [];
  filter: Filters = {} as Filters;
  fileUploaded: boolean = false;
  showUploadButton: boolean = false;
  selectedInputType: Types.CustomInputType = Types.CustomInputType.string;
  singleDatePicker = true;
  // isPrimaryTopicSelected: boolean = false;
  // isTopicsSelected: boolean = false;

  ngOnInit() {
    this.sharedDataService.getColumnNames().subscribe((names) => {
      const tempColumNames = [
        ...transformTableHeaders(names),
      ];
      this.columnNames = [...tempColumNames];
    });

    this._filterItemLocal = {
      index: this.filterItem.index,
      operator: this.filterItem.operator ? { value: this.filterItem.operator, label: this.filterItem.operator } : null,
      selectedColumn: this.filterItem.selectedColumn ? this.filterItem.selectedColumn : this.columnNames[0],
      selectedOperation: this.filterItem.selectedOperation
        ? this.filterItem.selectedOperation
        : this.operationContent[0],
      value: this.filterItem.value,
      isCluster: this.filterItem.isCluster,
    };
    this._selectedArrayValue.set({
      label: this._filterItemLocal.value as string,
      value: this._filterItemLocal.value as string,
    });
    this.initializeTypes();

    this.labelService.getLabelData().subscribe((data: LabelTypes) => {
      this.filter = data?.filter ?? {};
    });
    if (this._filterItemLocal.selectedColumn?.value === 'ContactId') {
      this.showUploadButton = true;
    }
    // this.isTopicsSelected = this._filterItemLocal.selectedColumn?.value === 'Topics';
    // this.isPrimaryTopicSelected = this._filterItemLocal.selectedColumn?.value === 'PrimaryTopic';

  }

  isTopicsSelected(): boolean {
    return this._filterItemLocal.selectedColumn?.value === 'Topics';
  }

  isPrimaryTopicSelected(): boolean {
    return this._filterItemLocal.selectedColumn?.value === 'PrimaryTopic';
  }

  isIntentSelected(): boolean {
    return this._filterItemLocal.selectedColumn?.value === 'Intent';
  }


  isArraySelected(): boolean {
    return this._filterItemLocal.selectedColumn?.type === Types.ColumnTypes.singleArray;
  }

  //check csv data
  handleFileData(fileName: string, parsedData: Array<{ [key: string]: string }>) {
    if (!Array.isArray(parsedData)) {
      console.error('Invalid data format');
      return;
    }
    // Check if 'Contact Id' column is present in the CSV
    const hasContactIdColumn = parsedData.length > 0 && 'Contact Id' in parsedData[0];

    if (!hasContactIdColumn) {
      console.error('Contact Id column is missing in the uploaded CSV');
      this.fileUploaded = false;
      this.fileName = '';
      this.showUploadButton = false;
      return;
    }

    const contactIds = parsedData.map((row) => row['Contact Id']).filter((id) => id);
    this._filterItemLocal.value = contactIds;
    this.fileUploaded = true;
    this.showUploadButton = true;
    this.fileName = fileName;
    if (this.fileUploaded) {
      this.handleUpdateFilterItem(true, !!this._filterItemLocal.value);
    }
  }

  initializeTypes = () => {
    if (this.filterItem.selectedColumn) {
      const columnValue = this.columnNames.find((item) => item.label === this.filterItem.selectedColumn?.label);

      if (columnValue) {
        if (this._filterItemLocal.selectedColumn) {
          this._filterItemLocal.selectedColumn.type = columnValue.type;
        }
        this.handleOperationContent(columnValue);
        this.handleInputContent(columnValue);
      }
    } else {
      this.operationContent = FILTER_OPERATIONS.string;
      this.selectedInputType = Types.CustomInputType.string;
      this._filterItemLocal.selectedOperation = FILTER_OPERATIONS.string[0];
    }
  };

  handleOperationContent = (value: Types.DropdownObjectType) => {
    // handle OperationContent
    if (value.type === Types.ColumnTypes.integer) {
      this.operationContent = FILTER_OPERATIONS.integer;
      this.showUploadButton = false;

      if (this.selectedInputType != Types.CustomInputType.number && !this.filterItem.selectedOperation) {
        this._filterItemLocal.selectedOperation = FILTER_OPERATIONS.integer[0];
      }
      this.selectedInputType = Types.CustomInputType.number;
    } else if (value.type === Types.ColumnTypes.date) {
      this.operationContent = FILTER_OPERATIONS.date;

      if (this._filterItemLocal.selectedOperation?.value === 'date_range') {
        this.singleDatePicker = false;
      } else {
        this.singleDatePicker = true;
      }
      if (this.selectedInputType != Types.CustomInputType.date && !this.filterItem.selectedOperation) {
        this._filterItemLocal.selectedOperation = FILTER_OPERATIONS.date[0];
      }
      this.selectedInputType = Types.CustomInputType.date;
    } else if (value.type === Types.ColumnTypes.string || value.type === undefined) {
      this.operationContent = FILTER_OPERATIONS.string;
      if (this.selectedInputType != Types.CustomInputType.string && !this.filterItem.selectedOperation) {
        this._filterItemLocal.selectedOperation = FILTER_OPERATIONS.string[0];
      }
    } else if (value.type === Types.ColumnTypes.array) {
      this.operationContent = FILTER_OPERATIONS.array;
      if (this.selectedInputType != Types.CustomInputType.array && !this.filterItem.selectedOperation) {
        this._filterItemLocal.selectedOperation = FILTER_OPERATIONS.array[0];
      }
      this.selectedInputType = Types.CustomInputType.array;
    } else if (value.type === Types.ColumnTypes.singleArray) {
      this.operationContent = FILTER_OPERATIONS.singleArray;
      if (this.selectedInputType != Types.CustomInputType.array && !this.filterItem.selectedOperation) {
        this._filterItemLocal.selectedOperation = FILTER_OPERATIONS.singleArray[0];
      }
      this.selectedInputType = Types.CustomInputType.singleArray;
    }

    if (
      this.filterItem.selectedOperation?.value === DisableContent.isEmpty.value ||
      this.filterItem.selectedOperation?.value === DisableContent.isNotEmpty.value
    ) {
      this.selectedInputType = Types.CustomInputType.disabled;
    }

    if (
      this.filterItem.selectedOperation?.value === DisableContent.isEmpty.value ||
      this.filterItem.selectedOperation?.value === DisableContent.isNotEmpty.value
    ) {
      this.selectedInputType = Types.CustomInputType.disabled;
    }
  };

  handleUpdateFilterItem = (keepReference = false, enableApplyButton = false) => {
    this.filterService.updateCondition(this._filterItemLocal, keepReference, enableApplyButton);
  };


  handleInputContent = (value: Types.DropdownObjectType) => {
    if (value.type === Types.ColumnTypes.singleArray) {
      this.filterService.getAllFilters().subscribe((filters) => {
        const filterKey = FilterKeyMap[value.value];

        if (filterKey) {
          const filterData = (filters.filters[filterKey] ?? []) as string[];
          const filteredData = this.filterAndMap(filterData);
          this._arrayData.set(filteredData);
        }
      });
    }
  };

  private filterAndMap(data: string[]): { value: string; label: string }[] {
    return data
      .filter((item) => item.trim() !== '')
      .map((item) => ({
        value: item,
        label: item,
      }));
  }

  handleColumnSelect = (value: Types.DropdownObjectType) => {
    this.handleOperationContent(value);
    this.handleInputContent(value);

    this._filterItemLocal.selectedColumn = value;
    this._filterItemLocal.value = '';

    // Handle "Contact ID" case
    if (value.value === 'ContactId') {
      this.showUploadButton = true;
      this.fileUploaded = false;
    }
    // Handle "Cluster" case
    else if (value.value === 'RootCause' || value.value === 'CustomerPainPoint') {
      this._filterItemLocal.isCluster = true;
      this.filterService.addEmptyCluster(this.filterItem.index, this._filterItemLocal.selectedColumn);
      this.showUploadButton = false;
      this.fileUploaded = false;
    }
    // Handle "Topics" case
    else if (value.value === 'Topics') {
      this.selectedInputType = Types.CustomInputType.array;
      this.showUploadButton = false;
      this.fileUploaded = false; // Reset file upload state
      this.handleUpdateFilterItem();
    }

    else if (value.value === 'PrimaryTopic') {
      this.selectedInputType = Types.CustomInputType.array;
      this.showUploadButton = false;
      this.fileUploaded = false; // Reset file upload state
      this.handleUpdateFilterItem();
    }
    else if (value.value === 'Intent') {
      this.selectedInputType = Types.CustomInputType.array;
      this.showUploadButton = false;
      this.fileUploaded = false; // Reset file upload state
      this.handleUpdateFilterItem();
    }
    // Handle all other cases
    else {
      this._filterItemLocal.isCluster = false;
      this.showUploadButton = false;
      this.fileUploaded = false; // Reset file upload state
      this.handleUpdateFilterItem();
    }
  };

  handleOperationSelect = (value: Types.DropdownObjectType) => {
    this._filterItemLocal.selectedOperation = value;
    let enableApplyButton = !!this._filterItemLocal.value?.length;

    if (value.label === DisableContent.isEmpty.label || value.label === DisableContent.isNotEmpty.label) {
      this.selectedInputType = Types.CustomInputType.disabled;
      enableApplyButton = true;
    } else if (this._filterItemLocal.selectedColumn) {
      this.selectedInputType = (this._filterItemLocal.selectedColumn.type ??
        Types.CustomInputType.string) as Types.CustomInputType;
    }

    if (value.value === 'date_range') {
      this.singleDatePicker = false;
    } else {
      this.singleDatePicker = true;
    }

    this.handleUpdateFilterItem(true, enableApplyButton);
  };

  handleOperatorSelect = (value: Types.DropdownObjectType) => {
    this._filterItemLocal.operator = value;
    this.filterService.changeAllOperators(value);
  };

  handleDeletePress = () => {
    this.filterService.removeCondition(this.filterItem.index);
  };

  handleInputChange = (input: string | string[]) => {
    this._filterItemLocal.value = input;
    this.handleUpdateFilterItem(true, !!input?.length);
  };

  handleInputArrayChange = (input: Types.DropdownObjectType) => {
    this._filterItemLocal.value = input.value;
    this._selectedArrayValue.set({ label: this._filterItemLocal.value as string, value: this._filterItemLocal.value as string });
    this.handleUpdateFilterItem(true, !!input?.value?.length);
  };

  handleInputTopicsChange = (input: string[]) => {
    this._filterItemLocal.value = input;
    this.handleUpdateFilterItem(true, !!input.length);
  };

  handleInputPrimaryTopicsChange = (input: string[]) => {
    this._filterItemLocal.value = input;
    this.handleUpdateFilterItem(true, !!input.length);
  };

  handleInputIntentChange = (input: string[]) => {
    this._filterItemLocal.value = input;
    this.handleUpdateFilterItem(true, !!input.length);
  };

  checkOperator = () => {
    return this.filterItem.index === 2;
  };

  getOperatorText = () => {
    return this.filterItem.operator;
  };
  private updateFilter() {
    this.filterService.updateCondition(this._filterItemLocal);
  }

  handleHiButtonClick() {
    const documentOption: Types.DropdownObjectType = { value: 'is', label: 'document' };
    this._filterItemLocal.selectedOperation = documentOption;
  }

  clearFileName() {
    this.fileName = null;
    this.fileUploaded = false;
    this._filterItemLocal.value = '';

    const nonDocumentOption = this.operationContent.find((option) => option.value !== 'document');
    if (nonDocumentOption) {
      this._filterItemLocal.selectedOperation = nonDocumentOption;
      this.updateFilter();
    }

    this.cdr.detectChanges();
  }
}