<div class="timezone-dropdown-container">
  <app-button [buttonText]="selectedValue?.label ?? ''" (onButtonClick)="handleButtonClick()"
    [clicked]="_dropdownOpened()" iconUrl="assets/icons/keyboard_arrow_down_new.svg"></app-button>

  @if (_dropdownOpened()) {
  <div class='dropdown-content-container' appOutSideClick (outSideClick)="onOutsideClick()">
    <div class="list-wrapper">
      <div class="list-item" *ngFor="let item of contentList" (click)="handleItemSelect($event, item)">
        <div class="list-item-wrapper">
          {{item.label}}
        </div>
      </div>
    </div>
  </div>
  }

</div>