import { Component, signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatMenuModule } from '@angular/material/menu';

import { SharedDataService } from '../../services/shared-data.service';
import { DropdownObjectType } from '../../shared/types';
import { ButtonComponent } from '../button/button.component';
import { TIMEZONE_LIST } from '../../shared/constant';
import { OutsideClickDirective } from '../../directives/outside-click.directive';

@Component({
  selector: 'timezone',
  standalone: true,
  imports: [CommonModule, MatMenuModule, ButtonComponent, OutsideClickDirective],
  templateUrl: './timezone.component.html',
  styleUrl: './timezone.component.scss',
})
export class TimezoneComponent {
  constructor(private sharedDataService: SharedDataService) {}
  selectedValue: DropdownObjectType | undefined | null = { label: 'UTC', value: 'UTC' };
  protected _dropdownOpened = signal<boolean>(false);
  contentList: Array<DropdownObjectType> = TIMEZONE_LIST;

  ngOnInit() {
    this.sharedDataService.getToggleValues().subscribe((values) => {
      this._dropdownOpened.set(values.timeZone);
    });
  }

  handleItemSelect = (event: MouseEvent, item: DropdownObjectType) => {
    this.sharedDataService.toggleTimeZone(false);
    this.selectedValue = item;
    this.sharedDataService.setSelectedTimezoneValue(item.value);
  };

  onOutsideClick = () => {
    this.sharedDataService.toggleTimeZone(false);
  };

  handleButtonClick = () => {
    this.sharedDataService.toggleTimeZone(!this._dropdownOpened());
  };
}
