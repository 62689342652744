import { Injectable, signal, WritableSignal } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import * as Types from '../shared/types';
import { InitialFilterData } from '../shared/constant';

@Injectable({
  providedIn: 'root',
})
export class FilterService {
  private _allFilter$: BehaviorSubject<Types.DataFilters> = new BehaviorSubject({} as Types.DataFilters);
  private _refreshData$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private _applyButtonEnabled$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private _filterData$: BehaviorSubject<Types.FilterCondition[]> = new BehaviorSubject([
    { ...InitialFilterData } as Types.FilterCondition,
  ]);
  private _savedFilterLabel$: BehaviorSubject<string> = new BehaviorSubject('');
  protected _savedFilterList = signal<Array<Types.FilterListResponse>>([]);
  private _saveButtonEnabled = signal<boolean>(false);
  private _filterDataDisplayed = signal<boolean>(false);

  constructor() {}

  public get filterDataDisplayedStatus(): WritableSignal<boolean> {
    return this._filterDataDisplayed;
  }

  setFilterDataDisplayedStatus(enabled: boolean): void {
    this._filterDataDisplayed.set(enabled);
  }

  public get saveButtonEnabled(): WritableSignal<boolean> {
    return this._saveButtonEnabled;
  }

  setSavedButtonEnabled(enabled: boolean): void {
    this._saveButtonEnabled.set(enabled);
  }

  savedFilterListState(): Types.FilterListResponse[] {
    return this._savedFilterList();
  }

  public get savedFilterList(): WritableSignal<Array<Types.FilterListResponse>> {
    return this._savedFilterList;
  }

  setSavedFilterList(filterList: Array<Types.FilterListResponse>): void {
    this._savedFilterList.set(filterList);
  }

  updateSavedFilterList(obj: Types.FilterListResponse, update = false): void {
    const updatedList = this._savedFilterList().map((item) => {
      if (update && item.tag === obj.tag) {
        return obj;
      }
      return item;
    });

    if (!update) {
      updatedList.push(obj);
    }

    this.setSavedFilterList(updatedList);
  }

  removeFilterFromList(tag: string): void {
    const updatedList = this._savedFilterList().filter((item) => item.tag !== tag);
    this.setSavedFilterList(updatedList);
    this.setSavedFilterLabel('');
    this.clearFilters();
    if (this._filterDataDisplayed()) {
      this.setRefreshDataStatus(true);
      this.setFilterDataDisplayedStatus(false);
    }
  }

  updateFilterData = (data: Types.FilterCondition[]) => {
    this._filterData$.next(data);
    this.setApplyButtonStatus(true);
  };

  setSavedFilterLabel = (data: string) => {
    this._savedFilterLabel$.next(data);
  };

  getSavedFilterLabel = () => {
    return this._savedFilterLabel$;
  };

  setAllFilters = (data: Types.DataFilters) => {
    this._allFilter$.next(data);
  };

  updateAllFilters = (data: Types.DataFilters) => {
    this._allFilter$.next({
      filters: {
        ...this._allFilter$.value.filters,
        ...data.filters,
      },
    });
  };

  getAllFilters = () => {
    return this._allFilter$;
  };

  getAllFiltersCurrentValue = () => {
    return this._allFilter$.value;
  };

  setRefreshDataStatus = (data: boolean) => {
    this._refreshData$.next(data);
  };

  getRefreshDataStatus = () => {
    return this._refreshData$;
  };

  getRefreshDataStatusValue = () => {
    return this._refreshData$.value;
  };

  setApplyButtonStatus = (data: boolean) => {
    this._applyButtonEnabled$.next(data);
  };

  getApplyButtonStatus = () => {
    return this._applyButtonEnabled$;
  };

  setFilterCondition = (data: Types.FilterCondition[]) => {
    this._filterData$.next([...data]);
  };

  getFilterCondition = () => {
    return this._filterData$;
  };

  getPrimaryTopics = () => {
    return this._filterData$;
  };

  getFilterConditionDataValue = () => {
    return this._filterData$.value;
  };

  addNewCondition = () => {
    const emptyConditionIndex = this._filterData$.value.findIndex((condition) => !condition.index);

    let tempCondition = {
      index: emptyConditionIndex > -1 ? 1 : this._filterData$.value.length + 1,
      operator: emptyConditionIndex > -1 ? null : 'AND',
      selectedColumn: undefined,
      selectedOperation: undefined,
      value: '',
      isCluster: false,
      clusterData: [],
    };

    if (emptyConditionIndex > -1) {
      // If an empty condition is found, replace it with the new tempCondition
      this._filterData$.value[emptyConditionIndex] = tempCondition;
      this._filterData$.next([...this._filterData$.value]);
    } else {
      if (this._filterData$.value.length > 1) {
        tempCondition.operator = this._filterData$.value[this._filterData$.value.length - 1]?.operator ?? null;
      }

      // If no empty condition is found, add the new tempCondition
      this._filterData$.next([...this._filterData$.value, tempCondition]);
    }
    this.setSavedButtonEnabled(true);
  };

  updateCondition = (filterItemLocal: Types.FilterItemLocal, keepReference = false, enableApplyButton = false) => {
    const conditionIndex = this._filterData$.value.findIndex((condition) => condition.index === filterItemLocal.index);

    if (conditionIndex > -1) {
      let updatedCondition: Types.FilterCondition = {
        ...this._filterData$.value[conditionIndex],
        operator: filterItemLocal.operator ? filterItemLocal.operator.label : null,
        selectedColumn: filterItemLocal.selectedColumn ? filterItemLocal.selectedColumn : undefined,
        selectedOperation: filterItemLocal.selectedOperation ? filterItemLocal.selectedOperation : undefined,
        value: filterItemLocal.value ?? '',
        isCluster: filterItemLocal.isCluster,
        clusterName: filterItemLocal.clusterName,
        clusterData: filterItemLocal?.clusterData || [],
      };

      // Update the condition in the array
      this._filterData$.value[conditionIndex] = updatedCondition;

      if (!keepReference) {
        // Emit the updated array
        this._filterData$.next([...this._filterData$.value]);
      }

      this.setSavedButtonEnabled(true);
      this._applyButtonEnabled$.next(enableApplyButton);
    } else {
      console.error('Condition not found for update.');
    }
  };

  removeCondition = (index: number) => {
    // Find the condition by index
    const conditionIndex = this._filterData$.value.findIndex((condition) => condition.index === index);

    // If the condition is found, remove it from the array
    if (conditionIndex > -1) {
      const updatedConditions = this._filterData$.value.filter((condition) => condition.index !== index);

      if (updatedConditions.length === 0) {
        this._filterData$.next([{ ...InitialFilterData } as Types.FilterCondition]);
        this.setRefreshDataStatus(true);
      } else {
        // Re-Index the remaining items
        const reindexedConditions = updatedConditions.map((item, index) => ({
          ...item,
          index: index + 1, // Reassign the index starting from 1
        }));
        // Update the observable with the new array
        this._filterData$.next([...reindexedConditions]);
      }
    }
  };

  addEmptyCluster = (conditionIndex: number, selectedColumn?: Types.DropdownObjectType, clusterName?: string) => {
    let initClusterItem: Types.ClusterData = {
      index: 1,
      selectedCluster: undefined,
      selectedOperation: undefined,
      value: '',
    };

    let updatedCondition: Types.FilterCondition = {
      ...this._filterData$.value[conditionIndex - 1],
      isCluster: true,
      clusterName: clusterName,
      selectedColumn: selectedColumn,
      clusterData: [initClusterItem],
    };

    this._filterData$.value[conditionIndex - 1] = updatedCondition;
    this._filterData$.next([...this._filterData$.value]);
  };

  addCluster = (clusterIndex: number, conditionIndex: number) => {
    let clusterItem: Types.ClusterData = {
      index: clusterIndex + 1,
      selectedCluster: undefined,
      selectedOperation: { label: '', value: '' },
      value: '',
    };

    let updatedCondition: Types.FilterCondition = {
      ...this._filterData$.value[conditionIndex - 1],
      clusterData: [...(this._filterData$.value[conditionIndex - 1]?.clusterData ?? []), clusterItem],
    };

    this._filterData$.value[conditionIndex - 1] = updatedCondition;
    this._filterData$.next([...this._filterData$.value]);
  };

  removeClusterItem = (clusterIndex: number, conditionIndex: number) => {
    const condition = this._filterData$.value[conditionIndex - 1];

    if (condition?.clusterData) {
      // Remove the cluster item
      const updatedRootCauseData = condition.clusterData.filter((_, index) => index !== clusterIndex - 1);

      // Re-Index the remaining items
      const reindexedRootCauseData = updatedRootCauseData.map((item, index) => ({
        ...item,
        index: index + 1, // Reassign the index starting from 1
      }));

      const updatedCondition: Types.FilterCondition = {
        ...condition,
        clusterData: reindexedRootCauseData,
      };

      // Update the filter data
      this._filterData$.value[conditionIndex - 1] = updatedCondition;
      this._filterData$.next([...this._filterData$.value]);
    }
  };

  changeAllOperators = (value: Types.DropdownObjectType) => {
    let updatedConditions = this._filterData$.value.map((item, index) => ({
      ...item,
      operator: index >= 1 ? value.label.toUpperCase() : item.operator?.toUpperCase(),
    }));

    this._filterData$.next([...updatedConditions]);
  };

  reIndexArrayAfterDragDrop = (data: Array<Types.FilterCondition>) => {
    const otherOperator = this._filterData$.value.find((item) => item.operator);

    const reindexedConditions = data.map((item, index) => ({
      ...item,
      index: index + 1, // Reassign the index starting from 1
      operator: index === 0 ? null : (otherOperator?.operator ?? 'And'),
    }));
    // Update the observable with the new array
    this._filterData$.next([...reindexedConditions]);
  };

  clearFilters = () => {
    this._filterData$.next([
      {
        index: 1,
        operator: null,
        selectedColumn: undefined,
        selectedOperation: undefined,
        value: '',
        isCluster: false,
        clusterData: [],
      } as Types.FilterCondition,
    ]);
  };
}
