import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { BehaviorSubject, Observable, catchError, map } from 'rxjs';
import {
  ColumnPreferenceType,
  FilterDeleteRequest,
  FilterListResponse,
  FilterSaveRequest,
  TableViewBody,
} from '../shared/types';

@Injectable({
  providedIn: 'root',
})
export class CommonService {
  private _loaderFlag$: BehaviorSubject<boolean>;

  rowValue: any;
  displayedColumns: any;
  tableColumnSearchKey: any;
  dataSource: any = new MatTableDataSource<any>();
  filteredDataSource: any = new MatTableDataSource<any>();
  headers: any[] = [];
  selectedDomain: any = '';
  // loaderFlag: Boolean = true;

  perPageItem: string = '';
  totalSize: number = 100;
  visiblePageBlock: number = 0;
  historyPageNumber: any = 1;

  exportCSVDataResponse: any;
  exportCSVDataResponseStatus: any;

  private baseUrl = process.env['API_DOMAIN_NAME'];

  constructor(private https: HttpClient) {
    this._loaderFlag$ = new BehaviorSubject(false);
    this.setPerPageItem();
  }

  setLoaderFlag = (data: boolean) => {
    this._loaderFlag$.next(data);
  };

  getLoaderFlag = () => {
    return this._loaderFlag$;
  };

  getHeaderNames(headers: any): string[] {
    return headers.map((header: any) => header.name);
  }

  getChartJSONData(): Observable<any> {
    return this.https.get<any>('assets/data/may7final.json');
  }

  private getAuthHeaders(): HttpHeaders {
    const accessToken = localStorage.getItem('authToken');
    if (!accessToken) {
      throw new Error('Access token not available.');
    }
    return new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: accessToken,
    });
  }

  private handleError(error: any): Observable<never> {
    console.error('An error occurred:', error);
    throw error;
  }

  private postRequest(endpoint: string, body: any, params?: HttpParams): Observable<any> {
    return this.https
      .post<any>(`${this.baseUrl}/${endpoint}`, body, { headers: this.getAuthHeaders(), params })
      .pipe(catchError(this.handleError));
  }

  private getRequest(endpoint: string, params?: HttpParams): Observable<any> {
    return this.https
      .get<any>(`${this.baseUrl}/${endpoint}`, { headers: this.getAuthHeaders(), params })
      .pipe(catchError(this.handleError));
  }

  fetchOverview(body: any): Observable<any> {
    return this.postRequest('api/calculate-overview', body);
  }

  setPerPageItem(): void {
    this.perPageItem = Math.ceil(this.totalSize * 0.1).toString();
  }

  updatePerPageItem(count: number): void {
    this.perPageItem = count.toString();
  }

  fetchTableValues(body: TableViewBody, pageSize: number, pageNumber: number): Observable<any> {
    if (body.search_term) {
      return this.searchRecords(body, pageSize, pageNumber);
    } else {
      return this.getRawTable(body, pageSize, pageNumber);
    }
  }

  getRawTable(body: any, pageSize: number, pageNumber: number): Observable<any> {
    const params = new HttpParams().set('page_size', pageSize.toString()).set('page_number', pageNumber.toString());
    return this.postRequest('api/get-raw-table', body, params);
  }

  searchRecords(body: any, pageSize: number, pageNumber: number): Observable<any> {
    const params = new HttpParams().set('page_size', pageSize.toString()).set('page_number', pageNumber.toString());
    return this.postRequest('api/search-records', body, params);
  }

  getUserColumnPreference(body: any): Observable<any> {
    return this.postRequest('get-data/get_user_column_preference', body);
  }

  updateUserColumnPreference(body: { preferences: ColumnPreferenceType[] }): Observable<any> {
    return this.postRequest('get-data/update_user_column_preference', body);
  }

  resetUserColumnPreference(body: any): Observable<any> {
    return this.postRequest('get-data/reset_user_column_preference', body);
  }

  fetchSentimentSankeyDataWithDate(body: any): Observable<any> {
    return this.postRequest('get-data/customers-sentiment-shift-over-time', body);
  }

  fetchPrimaryTopicSankeyDataWithDate(body: any): Observable<any> {
    return this.postRequest('get-data/primary-topic-and-intent-over-time', body);
  }

  fetchDataFiltersInsights(body: any): Observable<any> {
    return this.postRequest('api/filters/insights', body);
  }

  fetchDataFiltersClusters(body: any): Observable<any> {
    return this.postRequest('api/filters/clusters', body);
  }

  fetchDataFiltersConnectAttributes(body: any): Observable<any> {
    return this.postRequest('api/filters/connect-attributes', body);
  }

  saveFilters(body: FilterSaveRequest) {
    return this.postRequest('user/save-user-filter', body);
  }

  updateFilters(body: FilterSaveRequest) {
    return this.postRequest('user/update-user-filter', body);
  }

  getFiltersList(body: any): Observable<FilterListResponse[]> {
    return this.postRequest('user/get-user-filters', body);
  }

  deleteFilter(body: FilterDeleteRequest) {
    return this.postRequest('user/delete-user-filter', body);
  }

  fetchPrimaryTopicPercentageDataWithDate(body: any): Observable<any> {
    return this.postRequest('get-data/primary-topics-over-time', body);
  }

  fetchCustomerIntentPercentageDataWithDate(body: any): Observable<any> {
    return this.postRequest('get-data/customer-intent-over-time', body);
  }

  fetchPrimaryTopicFrequencyBarDataWithDate(body: any): Observable<any> {
    return this.postRequest('get-data/primary-topics-distribution', body);
  }

  fetchResolutionBarDataWithDate(body: any): Observable<any> {
    return this.postRequest('get-data/resolution-distribution', body);
  }

  sendChatMessage(query: string, domain: string): Observable<any> {
    const requestBody = { query, domain };
    return this.postRequest('data-navigator/query', requestBody); // Replace with actual endpoint
  }

  getPageRange(): string {
    if (isNaN(this.historyPageNumber) || this.historyPageNumber < 1) {
      return '';
    }
    if (parseInt(this.perPageItem) > this.totalSize) {
      const startIndex = 1;
      const endIndex = Math.min(startIndex + parseInt(this.perPageItem) - 1, this.totalSize);
      return `${startIndex} - ${endIndex} of ${this.totalSize}`;
    } else {
      const startIndex = (this.historyPageNumber - 1) * parseInt(this.perPageItem) + 1;
      const endIndex = Math.min(startIndex + parseInt(this.perPageItem) - 1, this.totalSize);
      return `${startIndex} - ${endIndex} of ${this.totalSize}`;
    }
  }

  exportDataToCSV(body: any): Observable<{ url: string }> {
    const endpoint = 'api/get-raw-data-csv';
    const accessToken = localStorage.getItem('authToken');

    if (!accessToken) {
      throw new Error('Access token not available.');
    }

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: accessToken,
    });

    return this.https.post<{ url: string }>(`${this.baseUrl}/${endpoint}`, body, { headers: headers });
  }

  exportDataToCSVFile(body: any): Observable<{ url: string }> {
    const endpoint = 'api/check_file_availability';
    const accessToken = localStorage.getItem('authToken');

    if (!accessToken) {
      throw new Error('Access token not available.');
    }

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: accessToken,
    });

    return this.https.post<{ url: string }>(`${this.baseUrl}/${endpoint}`, body, { headers: headers });
  }
}
