import { AfterViewInit, Component, computed, ElementRef, Input, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatRowDef, MatTableDataSource } from '@angular/material/table';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { CommonModule } from '@angular/common';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSelectModule } from '@angular/material/select';
import { FormsModule } from '@angular/forms';
import { ToastrModule, ToastrService } from 'ngx-toastr';
import { MatIconModule } from '@angular/material/icon';
import { Clipboard, ClipboardModule } from '@angular/cdk/clipboard';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { CdkScrollable, ScrollingModule } from '@angular/cdk/scrolling';
import { LabelService } from '../../../services/label.service';
import { CommonService } from '../../../services/common.service';
import { SharedDataService } from '../../../services/shared-data.service';
import { SkeletonLoaderComponent } from '../skeleton-loader/skeleton-loader.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import * as Types from '../../../shared/types';
import * as Constants from '../../../shared/constant';
import { convertConversationsToRowsColumns, transformTestToFilters } from '../../../shared/appUtil';
import { Labels } from '../../../shared/label.types';
import { FilterService } from '../../../services/filter.service';
import { debounceTime, Subscription } from 'rxjs';
import { IntersectionObserverDirective } from '../../../directives/intersection-observer.directive';
import { NotificationService } from '../../../services/notification.service';
import { ColumnMode, NgxDatatableModule } from '@m3rlin94/ngx-datatable';
import { SentimentClassPipe } from './pipes/sentiment-class-pipe.pipe';
import { FormattedValuePipe } from './pipes/formatted-value-pipe.pipe';
import { TruncateTopicPipe } from './pipes/truncate-topic-pipe.pipe';
import { FormatDatePipe } from './pipes/format-date-pipe.pipe';
import { TopicClassPipe } from './pipes/topic-class-pipe.pipe';
import { SentimentTextPipe } from './pipes/sentiment-text-pipe.pipe';
import { FormatTimestampPipe } from './pipes/format-timestamp-pipe.pipe';
import { LoadingComponent } from '../../loading/loading.component';

@Component({
  selector: 'app-tabular-view',
  standalone: true,
  imports: [
    MatTableModule,
    MatTooltipModule,
    MatPaginatorModule,
    MatSortModule,
    MatFormFieldModule,
    MatInputModule,
    CommonModule,
    MatTooltipModule,
    MatSelectModule,
    FormsModule,
    SkeletonLoaderComponent,
    ToastrModule,
    MatIconModule,
    MatTooltipModule,
    MatSelectModule,
    ClipboardModule,
    MatCheckboxModule,
    DragDropModule,
    ScrollingModule,
    MatRowDef,
    IntersectionObserverDirective,
    NgxDatatableModule,
    SentimentClassPipe,
    FormattedValuePipe,
    TruncateTopicPipe,
    FormatDatePipe,
    TopicClassPipe,
    SentimentTextPipe,
    FormatTimestampPipe,
    LoadingComponent,
  ],
  templateUrl: './tabular-view.component.html',
  styleUrls: ['./tabular-view.component.scss'],
})
export class TabularViewComponent implements OnInit, AfterViewInit {
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild(CdkScrollable)
  scrollable!: CdkScrollable;

  @Input() totalRow: any;
  tableViewBody: Types.TableViewBody = {} as Types.TableViewBody;
  // searchBody: Types.TableViewBody = {} as Types.TableViewBody;
  ELEMENT_DATA: Types.PeriodicElement[] = [];
  dataSource = new MatTableDataSource<Types.PeriodicElement>(this.ELEMENT_DATA);
  apiData: any;
  apiTotalRow!: number;
  labels: Labels = {} as Labels;
  tableHeaders: Types.ColumnHeaders[] = [];
  hiddenColumns: string[] = [];
  selectedColumns: string[] = [];
  newSelectedColumns: string[] = ['index', ...this.selectedColumns];
  topics: string[] = [];
  item: any = {};
  loaderFlag: boolean = true;
  isLoading = false;
  draggingColumnIndex: number | null = null;
  loadingData = false; // To prevent multiple requests
  loadingMorePageData: boolean = false;
  isInitialized: boolean = false; // Flag to check if ngOnInit is completed
  filterDataArray: Types.FilterCondition[] = [];
  pageSize: number = 50;
  pageNumber: number = 1;
  totalCount: number = 0;
  ColumnMode = ColumnMode;
  rows: { [key: string]: string }[] = [];
  columns: { prop: string | undefined; name?: string }[] = [];
  reorderedColumn: string | null = null;
  searchText: string = '';
  apiLoading = true;
  protected _tableViewFlag = computed<boolean>(() => this.sharedDataService.tableViewFlagStatus());
  private subscription: Subscription | undefined;

  constructor(
    private sharedDataService: SharedDataService,
    public commonService: CommonService,
    public labelService: LabelService,
    private toastr: ToastrService,
    private clipboard: Clipboard,
    private filterService: FilterService,
    private notificationService: NotificationService,
    private el: ElementRef
  ) {}

  ngOnInit() {
    this.filterDataArray = this.filterService.getFilterConditionDataValue();

    this.labelService.getLabelData().subscribe((data) => {
      this.labels = data?.labels ?? {};
    });

    this.sharedDataService.getColumnNames().subscribe((names) => {
      this.tableHeaders = names;
    });

    this.sharedDataService
      .getSelectedColumns()
      .pipe(debounceTime(200)) // adjust the debounce time
      .subscribe((columns) => {
        //this.loaderFlag = true
        const columnsSet: Set<string> = new Set(columns);
        const convertToColumnArray: {
          prop: string;
          name?: string;
        }[] = [];

        this.tableHeaders.forEach((header) => {
          if (columnsSet.has(header.name)) {
            convertToColumnArray.push({
              prop: header.key,
              name: header.name,
            });
          }
        });

        this.columns = [...convertToColumnArray];
      });

    this.commonService.getLoaderFlag().subscribe((flag) => {
      if (this.isInitialized) {
        this.loaderFlag = flag;
      }
    });

    this.allRows = this.commonService.filteredDataSource; // Load all data initially, but only render visible ones
    this.commonService.headers = this.tableHeaders;
    this.commonService.displayedColumns = this.commonService.getHeaderNames(this.tableHeaders);
    this.apiData = this.sharedDataService.getApiData();
    this.apiTotalRow = this.sharedDataService.getApiTotalRow();

    this.dataSource.data = this.apiData || [];
    this.commonService.dataSource = new MatTableDataSource(this.apiData || []);
    this.commonService.filteredDataSource = new MatTableDataSource(this.apiData || []);
    this.sharedDataService.setSelectedColumns();

    // Set isInitialized to true once ngOnInit logic has completed
    this.isInitialized = true;

    // Subscribe to the refresh data status to trigger API calls
    this.filterService.getRefreshDataStatus().subscribe((status) => {
      if (status) {
        this.loaderFlag = true;
        this.pageNumber = 1;
        // Call appropriate function based on searchText
        this.fetchTableValueAPIData(this.pageSize, this.pageNumber);
      }
    });

    this.subscription = this.sharedDataService.getDateRange().subscribe((date) => {
      this.tableViewBody = {
        start_date: date.startAPIDate,
        end_date: date.endAPIDate,
        filters: [],
        search_term: this.searchText,
      };
      if (date?.startAPIDate && date.dateSelected) {
        this.apiLoading = true;
        this.pageNumber = 1;
        this.fetchTableValueAPIData(this.pageSize, this.pageNumber);
      }
    });

    // Subscribe to search text updates
    this.sharedDataService.getSearchTerm$().subscribe((text) => {
      if (text.trim()) {
        this.loaderFlag = true;
        this.searchText = text;
        this.pageNumber = 1;
        this.tableViewBody.search_term = this.searchText;
        this.fetchTableValueAPIData(this.pageSize, this.pageNumber);
      } else if (this.searchText.length) {
        this.loaderFlag = true;
        this.searchText = '';
        this.tableViewBody.search_term = this.searchText;
        this.fetchTableValueAPIData(this.pageSize, this.pageNumber);
      }
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['this.commonService.rowValue']) {
      this.commonService.getPageRange();
    }
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.commonService.filteredDataSource.paginator = this.paginator;
    this.commonService.filteredDataSource.sort = this.sort;
    this.commonService.tableColumnSearchKey = 'Summary';

    // this.scrollable.elementScrolled().subscribe(() => this.onTableScroll());
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  onColumnReorder(event: any) {
    this.reorderedColumn = event.columns[event.newIndex].prop;
  }

  copyToClipboard(value: string) {
    this.clipboard.copy(value);
  }

  loadInitialData() {
    this.isLoading = true;
  }

  readonly headerHeight = 50;
  readonly rowHeight = 55;
  readonly pageLimit = 50;
  onScroll(offsetY: number) {
    // total height of all rows in the viewport
    const viewHeight = this.el.nativeElement.getBoundingClientRect().height - this.headerHeight;

    // check if we scrolled to the end of the viewport
    if (!this.isLoading && offsetY + viewHeight >= this.rows.length * this.rowHeight) {
      // total number of results to load
      let limit = this.pageLimit;

      // check if we haven't fetched any results yet
      if (this.rows.length === 0) {
        // calculate the number of rows that fit within viewport
        const pageSize = Math.ceil(viewHeight / this.rowHeight);

        // change the limit to pageSize such that we fill the first page entirely
        // (otherwise, we won't be able to scroll past it)
        limit = Math.max(pageSize, this.pageLimit);
      }
      this.loadPage(limit);
    }
  }

  private loadPage(limit: number) {
    // set the loading flag, which serves two purposes:
    // 1) it prevents the same page from being loaded twice
    // 2) it enables display of the loading indicator
    if (this.totalCount > this.pageNumber * this.pageSize) {
      // this.loadingMorePageData = true;
      this.pageNumber += 1;
      this.isLoading = true;
      this.fetchTableValueAPIData(this.pageSize, this.pageNumber);
      // this.searchTableValueData(this.pageSize, this.pageNumber);
    }
  }

  populateFilteredData(): Types.FilterRequest[] {
    if (this.filterService.getRefreshDataStatusValue()) {
      this.filterDataArray = this.filterService.getFilterConditionDataValue();
    }
    const filteredData: Types.FilterRequest[] = [...transformTestToFilters(this.filterDataArray)];
    return filteredData;
  }

  // Method to fetch table value data
  fetchTableValueAPIData(pageSize: number, pageNumber: number): void {
    this.tableViewBody.filters = this.populateFilteredData();
    this.tableViewBody.timeZone = this.sharedDataService.selectedTimezoneValue;
    if (this.tableViewBody.start_date && this._tableViewFlag()) {
      this.commonService.fetchTableValues(this.tableViewBody, pageSize, pageNumber).subscribe({
        next: (data: Types.RawTableType) => {
          this.totalCount = data.total_count;
          this.sharedDataService.setTotalCount(this.totalCount);
          const transformedData = convertConversationsToRowsColumns(data?.conversations);

          if (pageNumber === 1) {
            this.rows = transformedData.rows;
          } else {
            const rows = [...this.rows, ...transformedData.rows];
            this.rows = rows;
          }

          this.allRows = this.commonService.filteredDataSource;
          this.apiLoading = false;
          this.isLoading = false;
          this.commonService.setLoaderFlag(false);
          this.loaderFlag = false;
          this.loadingMorePageData = false;
          this.filterService.setRefreshDataStatus(false);
        },
        error: (error) => {
          console.error('API Error:', error);
          this.apiLoading = false;
          this.isLoading = false;
          this.loaderFlag = false;
          this.loadingMorePageData = false;
          this.commonService.setLoaderFlag(false);
          this.filterService.setRefreshDataStatus(false);
        },
      });
    } else {
      this.loaderFlag = false;
    }
  }

  exportPlot(): void {
    //this.toastr.info('Exporting in progress.', '');
    this.notificationService.processing('Processing', 'Exporting in progress.');
    this.commonService.exportDataToCSV(this.tableViewBody).subscribe({
      next: (response) => {
        this.commonService.exportCSVDataResponse = response;
        this.checkExportStatus();
      },
      error: (error) => {
        console.error('Export failed', error);
        this.notificationService.error('No data found', 'Something went wrong. Please try again.');
      },
    });
  }

  checkExportStatus(): void {
    this.exportCSVFileStatus();
  }

  exportCSVFileStatus(attempts: number = 0, retries: number = 0, failureCount: number = 0): void {
    const maxAttempts = 20; // Max attempts per retry
    const maxRetries = 3; // Max retry cycles
    const maxFailures = 10; // Max allowed failures

    if (failureCount > maxFailures) {
      this.notificationService.error('No data found', 'Something went wrong. Please try again.');
      return;
    }

    if (retries >= maxRetries) {
      this.notificationService.error('No data found', 'Something went wrong. Please try again.');
      return;
    }

    if (attempts >= maxAttempts) {
      // Restart the process with the next retry
      setTimeout(() => {
        this.exportCSVFileStatus(0, retries + 1, failureCount);
      }, 3000);
      return;
    }

    this.commonService.exportCSVDataResponseStatus = false;

    this.commonService.exportDataToCSVFile(this.commonService.exportCSVDataResponse).subscribe({
      next: (responseStatus) => {
        this.commonService.exportCSVDataResponseStatus = (
          responseStatus as unknown as Types.CsvExportResponse
        ).ready_to_download;

        if (this.commonService.exportCSVDataResponseStatus === true) {
          const data = (responseStatus as unknown as Types.CsvExportResponse).URL;
          this.downloadFile(data);
          this.commonService.exportCSVDataResponseStatus = false;
        } else {
          setTimeout(() => {
            this.exportCSVFileStatus(attempts + 1, retries, failureCount);
          }, 3000);
        }
      },
      error: (error) => {
        const updatedFailureCount = failureCount + 1;

        if (updatedFailureCount > maxFailures) {
          this.notificationService.error('No data found', 'Something went wrong. Please try again.');
        } else {
          setTimeout(() => {
            this.exportCSVFileStatus(attempts + 1, retries, updatedFailureCount);
          }, 3000);
        }
      },
    });
  }

  downloadFile(data: string): void {
    const anchor = document.createElement('a');
    anchor.href = data;
    const timestamp = this.getCurrentTimestamp();
    const filename = `Data_${timestamp}.csv`;
    anchor.download = filename;
    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);
    // this.toastr.success('File exported.', '');
    this.notificationService.success('export', 'Data Exported Successfully!');
  }

  private getCurrentTimestamp(): string {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0');
    const day = String(now.getDate()).padStart(2, '0');
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    const seconds = String(now.getSeconds()).padStart(2, '0');

    return `${year}${month}${day}_${hours}${minutes}${seconds}`;
  }

  selected: any = 5;
  ngAfterViewInitCalled = false;

  getHeaderNames(headers: any): string[] {
    return headers.map((header: any) => header.name);
  }

  filterData(dataSource: MatTableDataSource<any>, searchText: any) {
    const columnsToFilter = this.sharedDataService.getColumnNamesValue().map((nameObj) => nameObj.key);
    const array = this.commonService.dataSource.data;

    return array.filter((obj: any) => {
      return columnsToFilter.some((key) => {
        if (obj[key] && typeof obj[key] === 'string' && obj[key].toLowerCase().includes(searchText.toLowerCase())) {
          return true;
        }
        return false;
      });
    });
  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    const filteredData = this.filterData(this.commonService.dataSource, filterValue);
    this.commonService.filteredDataSource.data = filteredData;

    if (this.commonService.filteredDataSource.paginator) {
      this.commonService.filteredDataSource.paginator.firstPage();
    }
  }

  convertToNumber(value: string | number): number {
    return typeof value === 'string' ? parseInt(value, 10) : value;
  }

  parseSeconds(value: any): number {
    if (typeof value === 'string') {
      value = value.trim();
    }
    const num = typeof value === 'string' ? parseInt(value, 10) : value;
    return isNaN(num) ? NaN : num;
  }

  padZero(num: number): string {
    return num < 10 ? '0' + num : num.toString();
  }

  trackByFn(index: number, item: any): any {
    return item.id || index; // use a unique id for better performance
  }

  visibleDataSource: any[] = [];
  allRows: any[] = []; // This will hold all the data

  onRowVisible(isVisible: boolean, row: any) {
    if (isVisible && !this.visibleDataSource.includes(row)) {
      this.visibleDataSource.push(row); // Add row to visible data source when it enters the viewport
    }
  }
}
