import { DateFormatColumns, DisableContent, SingleArrayValues } from './constant';
import {
  ColumnHeaders,
  ColumnPreferenceType,
  ColumnTypes,
  ContactAttributes,
  Conversation,
  FilterCondition,
  FilterOption,
  FilterRequest,
  Insights,
  ClusterData,
  TableHeaderType,
} from './types';
import { FormatDatePipe } from '../components/dashboard/tabular-view/pipes/format-date-pipe.pipe';

export const convertToFilterOptionArray = (data: string[]): any[] => {
  return data
    ?.filter((item) => item && item.trim() !== '') // Remove empty items
    ?.map((item, index) => ({
      filterId: (index + 1).toString(),
      name: item,
      isSelected: true,
    }));
};

export const formatOptions = (names: TableHeaderType[]): FilterOption[] => {
  return names.map((item) => ({
    filterId: item.key,
    name: item.name,
    isSelected: true,
    tempIsSelected: true,
  }));
};

export const transformTableHeaders = (TableHeaders: ColumnHeaders[]) => {
  return TableHeaders.map((header) => ({
    label: header.name,
    value: header.key,
    type: header.type as ColumnTypes,
  }));
};

export const transformColumnPreferences = (preferences: Array<ColumnPreferenceType>): Array<ColumnHeaders> => {
  return preferences.map((preference) => {
    const header = {
      name: preference.column_label,
      key: preference.column_name,
      type: preference.column_data_type,
      number: preference.column_number,
      visibility: preference.column_visibility,
    };
 
    if (SingleArrayValues.includes(preference.column_name)) {
      header.type = ColumnTypes.singleArray;
    }
   
    return header;
  });
};

export const transformTestToFilters = (rawFilterData: FilterCondition[]): FilterRequest[] => {
  let error = false;

  if (rawFilterData.length) {
    const transformedData = rawFilterData.map((item) => {
      if (
        item.selectedOperation?.value !== DisableContent.isEmpty.value &&
        item.selectedOperation?.value !== DisableContent.isNotEmpty.value
      ) {
        if (!item.isCluster && !item.value) {
          error = true;
        }
      }

      const filter: FilterRequest = {
        logicalOperator: item.operator || 'AND', // Default to "AND" if operator is null
        columnName: item.selectedColumn?.value || 'string',
        operationType: item.selectedOperation?.value || 'string',
        value: item.value || '',
        isClusterFilter: item.isCluster || false,
        clusterFilters: [],
      };

      if (item.clusterData && item.clusterData.length > 0) {
        filter.clusterFilters = item.clusterData.map((rootCause: ClusterData) => {
          if (
            rootCause.selectedOperation?.value !== DisableContent.isEmpty.value &&
            rootCause.selectedOperation?.value !== DisableContent.isNotEmpty.value
          ) {
            if (!rootCause.selectedCluster || !rootCause.value) {
              error = true;
            }
          }

          return {
            clusterName: 'RootCauseClustered', // Assuming default value as it is not in `rawFilterData`
            clusterValue: rootCause.selectedCluster?.value || 'string',
            columnName: 'RootCause',
            operationType: rootCause.selectedOperation?.value || 'string',
            columnValue: rootCause.value || '',
          };
        });
      }

      return filter;
    });

    return error ? [] : transformedData;
  }

  return [];
};

export const convertToFilterCondition = (filters: FilterRequest[]): FilterCondition[] => {
  return filters.map((filter, index) => {
    // Map each filter to FilterCondition
    const filterCondition: FilterCondition = {
      index: index + 1,
      operator: filter.logicalOperator,
      selectedColumn: {
        value: filter.columnName,
        label: filter.columnName,
      },
      selectedOperation: {
        value: filter.operationType,
        label: filter.operationType,
      },
      value: filter.value,
      isCluster: filter.isClusterFilter,
      clusterData: filter.clusterFilters.map((clusterFilter, clusterIndex) => ({
        index: clusterIndex + 1,
        selectedCluster: {
          value: clusterFilter.clusterValue,
          label: clusterFilter.clusterValue,
        },
        selectedOperation: {
          value: clusterFilter.operationType,
          label: clusterFilter.operationType,
        },
        value: clusterFilter.columnValue,
      })),
    };

    return filterCondition;
  });
};

// Function to convert Conversation array to TableHeaderType array
export const convertConversationsToTableHeaderType = (
  conversations: Array<Conversation>
): Array<{ [key: string]: string }> => {
  const result: Array<any> = [];

  conversations.forEach((conversation) => {
    let oneRecord = {};
    // For each property in the Conversation object
    for (const key in conversation) {
      if (conversation.hasOwnProperty(key)) {
        if (key == 'Insights') {
          for (const insightsKey in conversation[key as keyof Conversation] as Insights) {
            oneRecord = {
              ...oneRecord,
              [insightsKey]:
                conversation.Insights[insightsKey as keyof Insights] !== null
                  ? conversation.Insights[insightsKey as keyof Insights]
                  : 'null',
            };
          }
        } else if (key == 'ContactAttributes') {
          for (const contactAttributeKey in conversation[key as keyof Conversation] as ContactAttributes) {
            oneRecord = {
              ...oneRecord,
              [contactAttributeKey]:
                conversation.ContactAttributes[contactAttributeKey as keyof ContactAttributes] !== null
                  ? conversation.ContactAttributes[contactAttributeKey as keyof ContactAttributes]
                  : 'null',
            };
          }
        } else {
          // Push the key-value pair as a TableHeaderType object
          oneRecord = {
            ...oneRecord,
            [key]: conversation[key as keyof Conversation] !== null ? conversation[key as keyof Conversation] : 'null',
          };
        }
      }
    }

    result.push(oneRecord);
  });

  return result;
};

export const delayLoading = (seconds: number): Promise<void> => {
  return new Promise((resolve) => setTimeout(resolve, seconds * 1000));
};

const isValidDate = (dateString: string) => {
  const date = new Date(dateString);
  return !isNaN(date.getTime());
};

const formatValue = (key: any, value: any): string => {
  if (DateFormatColumns.includes(key)) {
    const datePipe = new FormatDatePipe(); // Create an instance of the pipe
    return value !== '' && value !== 'NA' ? datePipe.transform(value) : 'null'; // Format the date
  }

  return value !== null ? value : 'null'; // Return formatted value or 'null'
};

export const convertConversationsToRowsColumns = (
  conversations: Array<Conversation>
): { rows: Array<{ [key: string]: string }>; columns: Array<{ prop?: string; name?: string }> } => {
  const rows: Array<any> = [];
  const columnsSet: Set<string> = new Set(); // Use a Set to store unique column names

  conversations.forEach((conversation) => {
    let oneRecord = {};

    // For each property in the Conversation object
    for (const key in conversation) {
      if (conversation.hasOwnProperty(key)) {
        if (key == 'Insights') {
          for (const insightsKey in conversation[key as keyof Conversation] as Insights) {
            oneRecord = {
              ...oneRecord,
              [insightsKey]: formatValue(insightsKey, conversation.Insights[insightsKey as keyof Insights]),
            };
            columnsSet.add(insightsKey); // Add to columns set
          }
        } else if (key == 'ContactAttributes') {
          for (const contactAttributeKey in conversation[key as keyof Conversation] as ContactAttributes) {
            oneRecord = {
              ...oneRecord,
              [contactAttributeKey]: formatValue(
                contactAttributeKey,
                conversation.ContactAttributes[contactAttributeKey as keyof ContactAttributes]
              ),
            };
            columnsSet.add(contactAttributeKey); // Add to columns set
          }
        } else {
          oneRecord = {
            ...oneRecord,
            [key]: formatValue(key, conversation[key as keyof Conversation]),
          };
          columnsSet.add(key); // Add to columns set
        }
      }
    }

    rows.push(oneRecord);
  });

  // Create columns array from the Set of unique keys
  const columns = Array.from(columnsSet).map((column) => ({ prop: column }));

  return { rows, columns };
};
