<div class="input-wrapper">
  <date-range-picker *ngIf="customInputType == 'date'; else otherInput" [singleDatePicker]="singleDatePicker"
    [showFilterUI]="true" customFormat="MMM DD, YYYY" (onDateSelect)="handleDateSelect($event)">
  </date-range-picker>

  <ng-template #otherInput>
    <input *ngIf="customInputType !== 'disabled';else disabledInput" [type]="getInputType()" [(ngModel)]="inputText"
      (ngModelChange)="handleInputChange($event)" [placeholder]="placeholderText" (click)="stopPropagation($event)"
      [attr.maxlength]="maxLength !== undefined ? maxLength : null" class="custom-input">
    <ng-template #disabledInput>
      <div class="disabled-input">Enter Value</div>
    </ng-template>
  </ng-template>
</div>