import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { ToggleComponent } from '../../hide-fields/toggle/toggle.component';
import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { OutsideClickDirective } from '../../../directives/outside-click.directive';
import { CommonService } from '../../../services/common.service';
import { FilterService } from '../../../services/filter.service';
import { LabelService } from '../../../services/label.service';
import { Filters, LabelTypes } from '../../../shared/label.types';

@Component({
  selector: 'app-intent',
  standalone: true,
  imports: [CommonModule, MatSlideToggleModule, FormsModule, ToggleComponent, OutsideClickDirective],
  templateUrl: './intent.component.html',
  styleUrl: './intent.component.scss'
})
export class IntentComponent {
  constructor(
    public commonService: CommonService,
    public filterService: FilterService,
    private labelService: LabelService
  ) { }

  @Input() inputText: string | string[] = [''];
  @Output() onInputChange = new EventEmitter<string[]>();

  isOptionSelected = false;
  dropdownOpened: boolean = false;
  searchQuery: string = '';
  selectedOptions: string[] = [];
  primaryTopics: { label: string; tempIsSelected: boolean }[] = [];
  filteredCount: number = 0;
  topicsLabels: Filters = {} as Filters;
  selectedOptionText: string = this.topicsLabels.select_an_option;

  ngOnInit() {
    this.labelService.getLabelData().subscribe((data: LabelTypes) => {
      this.topicsLabels = data?.filter ?? {};
    });

    this.filterService.getAllFilters().subscribe((filters) => {
      const filterListPrimaryTopics = filters.filters.intent ?? '';
      this.primaryTopics = filterListPrimaryTopics
        .filter((primarytopic) => primarytopic.trim() !== '')
        .map((primarytopic) => ({
          label: primarytopic,
          tempIsSelected: this.inputText.includes(primarytopic),
        }));
      this.updateSelectedOptions();

      this.updateFiltersWithPrimaryTopics();
    });
  }

  updateFiltersWithPrimaryTopics(): void { }

  handleButtonClick(event: Event): void {
    this.dropdownOpened = !this.dropdownOpened;
  }

  stopPropagation(event: Event): void {
    event.stopPropagation();
  }

  clearSearch(): void {
    this.searchQuery = '';
  }

  onOutsideClick(): void {
    this.dropdownOpened = false;
  }

  handleItemSelect(item: { label: string; tempIsSelected: boolean }): void {
    item.tempIsSelected = !item.tempIsSelected;
    this.updateSelectedOptions();
  }

  updateSelectedOptions(): void {
    this.selectedOptions = this.primaryTopics.filter((f) => f.tempIsSelected).map((f) => f.label);
    if (this.onInputChange) {
      this.onInputChange.emit(this.selectedOptions);
    }
    this.updateSelectedOptionText();
  }

  onValueChange(state: 'on' | 'off', item: { label: string; tempIsSelected: boolean }): void {
    item.tempIsSelected = state === 'on';
    this.updateSelectedOptions();
  }

  updateSelectedOptionText(): void {
    const selectedCount = this.selectedOptions.length;
    if (selectedCount === 0) {
      this.selectedOptionText = 'Select an option';
      this.isOptionSelected = false;
    } else if (selectedCount === 1) {
      this.selectedOptionText = this.selectedOptions[0];
      this.isOptionSelected = true;
    } else {
      this.selectedOptionText = `${selectedCount} options selected`;
      this.isOptionSelected = true;
    }
  }

  get filteredTopics() {
    const query = this.searchQuery.trim().toLowerCase();
    if (!query) {
      return { topics: this.primaryTopics, count: this.primaryTopics.length };
    }

    const filtered = this.primaryTopics.filter((topic) => topic.label.toLowerCase().indexOf(query) !== -1);

    return { topics: filtered, count: filtered.length };
  }
  get getPrimaryTopics() {
    return this.filteredTopics.topics;
  }

  get topicCount() {
    return this.filteredTopics.count;
  }
}
