<div class="select-container">
    <div class="select-button" (click)="handleButtonClick($event)">
        <div class="button-text" [ngClass]="{'placeholder': !isOptionSelected, 'selected': isOptionSelected}"
            [matTooltip]="isOptionSelected ? selectedClusterText : ''"
            [matTooltipDisabled]="!isOptionSelected || selectedClusterText.length <= 15" matTooltipPosition="above">
            {{ truncatedClusterText }}
        </div>

        <div class="button-icon">
            <img src="assets/icons/keyboard_arrow_different.svg">
        </div>
    </div>

    <div class="dropdown-content-container" *ngIf="dropdownOpened" appOutSideClick (outSideClick)="onOutsideClick()">
        <div class="container-header">
            <div class="header-title" *ngIf="listHeader">Select Filter</div>
        </div>
        <div class="option-search-container">
            <input type="text" [(ngModel)]="searchQuery" placeholder="Search" (click)="stopPropagation($event)"
                class="custom-input">
            <span class="close-icon" *ngIf="searchQuery" (click)="clearSearch(); $event.stopPropagation()">
                <img src="../../../assets/icons/close.svg">
            </span>
        </div>

        <div class="columns-list">
            <div class="column-item" *ngFor="let item of getFilteredTopics(); let i = index"
                (click)="handleOptionSelect($event,item)">
                <div class="column-name">{{ item.tag }}</div>
            </div>
        </div>
    </div>
</div>