<div class="data-explorer-container">

  @if (apiLoading) {
  <div class="loader-container">
    <loading></loading>
  </div>

  } @else {
  <div class="result-container">

    <!-- Go back section -->
    <div class="go_back_class" *ngIf="!tableViewFlag && (getVisibleChartCount() === 1)">
      <span (click)="popFromPathStack()">
        <button>
          <img src="../assets/icons/go_back.svg" alt="Go Back">
        </button>
        <span>{{labels.go_back_check}}</span>
      </span>
    </div>

    <!-- Highcharts Plots container  -->
    <div class="chart-container" *ngIf="!tableViewFlag"
      [ngClass]="{'custom-chart-container': getVisibleChartCount() === 1}">
      <!-- Chart (0,0)    Title: Primary topic over time
                                    Type: Percentage
                                    Data: Percentage of primary topic vs date -->
      <div class="overview-container" *ngIf="percentageAreaPrimaryTopicChartVisible" id="PrimaryTopicsOverview"
        #divToDownload1>
        <div class="overview-header">
          <h3 class="left-section">
            <span *ngIf="!loaderFlag">{{labels.primary_topic_over_time}}</span>
            <div class="left-section-loader">
              <app-skeleton-loader *ngIf="loaderFlag" [width]="'40px'" [height]="'24px'"></app-skeleton-loader>
              <app-skeleton-loader *ngIf="loaderFlag" [width]="'152px'" [height]="'24px'"></app-skeleton-loader>
            </div>
          </h3>
          <div class="right-section">
            <div class="right-section-btn-container">
              <button *ngIf="getVisibleChartCount() !== 1" class="btn view-download-btn" (click)="togglePercentageAreaPrimaryTopicChart(); 
                                pushToPathStack('Primary Topics Trends Over Time')">
                <img src="assets/icons/view-icon-grey.svg" alt="visibility" matTooltip="View Chart" />
              </button>
              <button class="btn view-download-btn" (click)="downloadAsJPEG('PrimaryTopicsOverview')">
                <img src="assets/icons/export_icon_grey.svg" alt="visibility" matTooltip="Export Chart" />
              </button>
            </div>
          </div>
        </div>
        <div class="overview-content load-chart-container">
          <div class="charts-container">
            <ng-container *ngIf="!loaderFlag">
              <highcharts-chart *ngIf="!noDataFoundFlag" [Highcharts]="Highcharts"
                [options]="percentageAreaPrimaryTopicChartOptions"
                [style]="getChartStyle(percentageAreaPrimaryTopicChartVisible)">
              </highcharts-chart>
              <div *ngIf="noDataFoundFlag" class="noDataFoundStyle"
                [style]="getChartStyle(percentageAreaPrimaryTopicChartVisible)">
                <p>{{labels.no_data_found}}</p>
              </div>
            </ng-container>

            <div *ngIf="loaderFlag" class="chart-load main-view-conatiner-loader"
              [style]="getChartStyle(percentageAreaPrimaryTopicChartVisible)">
              <app-skeleton-loader [width]="'100%'" [height]="'100%'"></app-skeleton-loader>
            </div>
          </div>
        </div>
      </div>

      <!-- Chart (0,1)    Title: Customer intent over time
                                    Type: Percentage
                                    Data: Percentage of intent vs date-->
      <div class="overview-container" *ngIf="percentageAreaCustomerIntentChartVisible"
        id="CustomerIntentsTrendsOverTime" #divToDownload2>
        <div class="overview-header">
          <h3 class="left-section">
            <span *ngIf="!loaderFlag">{{labels.customer_intent_over_time}}</span>
            <div class="left-section-loader">
              <app-skeleton-loader *ngIf="loaderFlag" [width]="'40px'" [height]="'24px'"></app-skeleton-loader>
              <app-skeleton-loader *ngIf="loaderFlag" [width]="'152px'" [height]="'24px'"></app-skeleton-loader>
            </div>
          </h3>
          <div class="right-section">
            <div class="right-section-btn-container">
              <button class="btn view-download-btn" matTooltip="View Chart" *ngIf="getVisibleChartCount() !== 1"
                (click)="toggleCustomerIntentPercentageAreaChart(); pushToPathStack('Customer Intents Trends Over Time')">
                <img src="assets/icons/view-icon-grey.svg" alt="visibility" />
              </button>
              <button class="btn view-download-btn" (click)="downloadAsJPEG('CustomerIntentsTrendsOverTime')"
                matTooltip="Export Chart">
                <img src="assets/icons/export_icon_grey.svg" alt="visibility" />
              </button>
            </div>
          </div>
        </div>
        <div class="overview-content load-chart-container">
          <div class="charts-container">

            <ng-container *ngIf="!loaderFlag">
              <highcharts-chart *ngIf="!noDataFoundFlag" [Highcharts]="Highcharts"
                [options]="percentageAreaCustomerIntentChartOptions"
                [style]="getChartStyle(percentageAreaCustomerIntentChartVisible)">
              </highcharts-chart>
              <div *ngIf="noDataFoundFlag" class="noDataFoundStyle"
                [style]="getChartStyle(percentageAreaCustomerIntentChartVisible)">
                <p>{{labels.no_data_found}}</p>
              </div>
            </ng-container>

            <div *ngIf="loaderFlag" class="chart-load main-view-conatiner-loader"
              [style]="getChartStyle(percentageAreaCustomerIntentChartVisible)">
              <app-skeleton-loader [width]="'100%'" [height]="'100%'"></app-skeleton-loader>
            </div>
          </div>
        </div>
      </div>

      <!-- chart (2, 0)   Title: Relationships between Primary Topic & Customer Intent
                                    Type: Sankey
                                    Data: Resolution vs frequency-->
      <div class="overview-container" *ngIf="sankeyDiagram1Visible"
        id="RelationshipsBetweenPrimaryTopicsCustomerIntents" #divToDownload5>
        <div class="overview-header">
          <h3 class="left-section">
            <span *ngIf="!loaderFlag">{{labels.relationships_between_Primary_Topic_Customer_Intent}}</span>
            <div class="left-section-loader">
              <app-skeleton-loader *ngIf="loaderFlag" [width]="'40px'" [height]="'24px'"></app-skeleton-loader>
              <app-skeleton-loader *ngIf="loaderFlag" [width]="'152px'" [height]="'24px'"></app-skeleton-loader>
            </div>
          </h3>
          <div class="right-section">
            <div class="right-section-btn-container">
              <button *ngIf="getVisibleChartCount() !== 1" class="btn view-download-btn" matTooltip="View Chart"
                (click)="toggleSankeyDiagram1(); pushToPathStack('Relationships between Primary Topics & Customer Intents')">
                <img src="assets/icons/view-icon-grey.svg" alt="visibility" />
              </button>
              <button class="btn view-download-btn"
                (click)="downloadAsJPEG('RelationshipsBetweenPrimaryTopicsCustomerIntents')" matTooltip="Export Chart">
                <img src="assets/icons/export_icon_grey.svg" alt="visibility" />
              </button>
            </div>
          </div>
        </div>
        <div class="overview-content load-chart-container">
          <div class="charts-container">

            <ng-container *ngIf="!loaderFlag">
              <highcharts-chart *ngIf="!noDataFoundFlag" [Highcharts]="Highcharts" [options]="sankeyDiagram1Options"
                [style]="getChartStyle(sankeyDiagram1Visible)">
              </highcharts-chart>
              <div *ngIf="noDataFoundFlag" class="noDataFoundStyle" [style]="getChartStyle(sankeyDiagram1Visible)">
                <p>{{labels.no_data_found}}</p>
              </div>
            </ng-container>
            <div *ngIf="loaderFlag" class="chart-load main-view-conatiner-loader"
              [style]="getChartStyle(sankeyDiagram1Visible)">
              <app-skeleton-loader [width]="'100%'" [height]="'100%'"></app-skeleton-loader>
            </div>
          </div>
        </div>
      </div>

      <!-- chart (2, 1)   Title: Sentiment flow
                                    Type: Sankey
                                    Data: CustomerSentimentAtStart vs CustomerSentimentAtEnd-->
      <div class="overview-container" *ngIf="sankeyDiagram2Visible" id="ConversationSentimentFlow" #divToDownload6>
        <div class="overview-header">
          <h3 class="left-section">
            <span *ngIf="!loaderFlag">{{labels.conversation_sentiment_flow}}</span>
            <div class="left-section-loader">
              <app-skeleton-loader *ngIf="loaderFlag" [width]="'40px'" [height]="'24px'"></app-skeleton-loader>
              <app-skeleton-loader *ngIf="loaderFlag" [width]="'152px'" [height]="'24px'"></app-skeleton-loader>
            </div>
          </h3>
          <div class="right-section">
            <div class="right-section-btn-container">
              <button *ngIf="getVisibleChartCount() !== 1" class="btn view-download-btn" (click)="toggleSankeyDiagram2(); 
                                    pushToPathStack('Conversation Sentiment Flow')" matTooltip="View Chart">
                <img src="assets/icons/view-icon-grey.svg" alt="visibility" />
              </button>
              <button class="btn view-download-btn" (click)="downloadAsJPEG('ConversationSentimentFlow')"
                matTooltip="Export Chart">
                <img src="assets/icons/export_icon_grey.svg" alt="visibility" />
              </button>
            </div>
          </div>
        </div>
        <div class="overview-content load-chart-container">
          <div class="charts-container">
            <ng-container *ngIf="!loaderFlag">
              <highcharts-chart *ngIf="!noDataFoundFlag" [Highcharts]="Highcharts" [options]="sankeyDiagram2Options"
                [style]="getChartStyle(sankeyDiagram2Visible)">
              </highcharts-chart>
              <div *ngIf="noDataFoundFlag" class="noDataFoundStyle" [style]="getChartStyle(sankeyDiagram2Visible)">
                <p>{{labels.no_data_found}}</p>
              </div>
            </ng-container>
            <div *ngIf="loaderFlag" class="chart-load main-view-conatiner-loader"
              [style]="getChartStyle(sankeyDiagram2Visible)">
              <app-skeleton-loader [width]="'100%'" [height]="'100%'"></app-skeleton-loader>
            </div>
          </div>
        </div>
      </div>

      <!-- Chart (1,0)    Title: Primary topic distribution
                                    Type: Bar
                                    Data: Primary topic vs frequency-->
      <div class="overview-container" *ngIf="primaryTopicBarChartVisible" id="PrimaryTopicsOverview" #divToDownload3>
        <div class="overview-header">
          <h3 class="left-section">
            <span *ngIf="!loaderFlag">{{labels.primary_topic_distribution}}</span>
            <div class="left-section-loader">
              <app-skeleton-loader *ngIf="loaderFlag" [width]="'40px'" [height]="'24px'"></app-skeleton-loader>
              <app-skeleton-loader *ngIf="loaderFlag" [width]="'152px'" [height]="'24px'"></app-skeleton-loader>
            </div>
          </h3>
          <div class="right-section">
            <div class="right-section-btn-container">
              <button class="btn view-download-btn" *ngIf="getVisibleChartCount() !== 1" matTooltip="View Chart"
                (click)="togglePrimaryTopicBarChart(); pushToPathStack('Primary Topics Trends Over Time')">
                <img src="assets/icons/view-icon-grey.svg" alt="visibility" />
              </button>
              <button class="btn view-download-btn" (click)="downloadAsJPEG('PrimaryTopicsOverview')"
                matTooltip="Export Chart">
                <img src="assets/icons/export_icon_grey.svg" alt="visibility" />
              </button>
            </div>
          </div>
        </div>
        <div class="overview-content load-chart-container">
          <div class="charts-container">

            <ng-container *ngIf="!loaderFlag">
              <highcharts-chart *ngIf="!noDataFoundFlag" [Highcharts]="Highcharts"
                [options]="primaryTopicBarChartOptions" [style]="getChartStyle(primaryTopicBarChartVisible)">
              </highcharts-chart>
              <div *ngIf="noDataFoundFlag" class="noDataFoundStyle"
                [style]="getChartStyle(primaryTopicBarChartVisible)">
                <p>{{labels.no_data_found}}</p>
              </div>
            </ng-container>
            <div *ngIf="loaderFlag" class="chart-load main-view-conatiner-loader"
              [style]="getChartStyle(primaryTopicBarChartVisible)">
              <app-skeleton-loader [width]="'100%'" [height]="'100%'"></app-skeleton-loader>
            </div>
          </div>
        </div>
      </div>

      <!-- chart (1,1)    Title: Distribution of resolution
                                                        Type: Bar
                                                        Data: Resolution vs frequency -->
      <div class="overview-container" *ngIf="resolutionBarChartVisible" id="ResolutionsOverview" #divToDownload4>
        <div class="overview-header">
          <h3 class="left-section">
            <span *ngIf="!loaderFlag">{{labels.distribution_of_resolution}}</span>
            <div class="left-section-loader">
              <app-skeleton-loader *ngIf="loaderFlag" [width]="'40px'" [height]="'24px'"></app-skeleton-loader>
              <app-skeleton-loader *ngIf="loaderFlag" [width]="'152px'" [height]="'24px'"></app-skeleton-loader>
            </div>
          </h3>
          <div class="right-section">
            <div class="right-section-btn-container">
              <button class="btn view-download-btn" *ngIf="getVisibleChartCount() !== 1"
                (click)="toggleResolutionBarChart(); pushToPathStack('Resolutions Overview')" matTooltip="View Chart">
                <img src="assets/icons/view-icon-grey.svg" alt="visibility" />
              </button>
              <button class="btn view-download-btn" (click)="downloadAsJPEG('ResolutionsOverview')"
                matTooltip="Export Chart">
                <img src="assets/icons/export_icon_grey.svg" alt="visibility" />
              </button>
            </div>
          </div>
        </div>
        <div class="overview-content load-chart-container">
          <div class="charts-container">

            <ng-container *ngIf="!loaderFlag">
              <highcharts-chart *ngIf="!noDataFoundFlag" [Highcharts]="Highcharts" [options]="resolutionBarChartOptions"
                [style]="getChartStyle(resolutionBarChartVisible)">
              </highcharts-chart>
              <div *ngIf="noDataFoundFlag" class="noDataFoundStyle" [style]="getChartStyle(resolutionBarChartVisible)">
                <p>{{labels.no_data_found}}</p>
              </div>
            </ng-container>
            <div *ngIf="loaderFlag" class="chart-load main-view-conatiner-loader"
              [style]="getChartStyle(resolutionBarChartVisible)">
              <app-skeleton-loader [width]="'100%'" [height]="'100%'"></app-skeleton-loader>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Disclaimer section -->
    <!-- <div class="disclaimer">
          <span class="disclaimer-title">{{labels.disclaimer}}</span>
          <span class="disclaimer-message">{{labels.disclaimer_text}}</span>
        </div> -->

  </div>
  }
</div>